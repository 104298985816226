import React from "react";
import ReportSectionSkeleton from "../../../../../components/ui/Loaders/ReportSectionSkeleton";
import TextSkeleton from "../../../../../components/ui/Loaders/TextSkeleton";
import AccordionSectionSpacer from "../../../../../components/ui/Accordion/AccordionSectionSpacer";

export default function ZoningUseClassificationSkeleton() {
  return (
    <ReportSectionSkeleton title secondaryTitle childPadding={false}>
      <div className="ml-1.5 mt-1.5 sm:ml-5 sm:mt-2.5">
        <AccordionSectionSpacer>
          <ReportSectionSkeleton title secondaryTitle />
          <ReportSectionSkeleton title secondaryTitle />
          <ReportSectionSkeleton title secondaryTitle />
          <ReportSectionSkeleton title secondaryTitle />
          <ReportSectionSkeleton title secondaryTitle />
          <ReportSectionSkeleton title secondaryTitle />
          <ReportSectionSkeleton title secondaryTitle />
          <ReportSectionSkeleton title secondaryTitle />
          <ReportSectionSkeleton title secondaryTitle />
          <ReportSectionSkeleton title secondaryTitle />
        </AccordionSectionSpacer>
      </div>
    </ReportSectionSkeleton>
  );
}
