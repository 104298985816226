import { ParsedAnnotation } from "../../components/Answer";
import { useEffect, useState, useCallback } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import DocumentRenderer from "../../features/ReportGenerator/AnswerToUserQuestion/components/DocumentRenderer/DocumentRenderer";

interface Props {
  initialAnnotations?: string[];
}

const DocumentViewer = ({ initialAnnotations }: Props) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string | null>(null);
  const [debugInfo, setDebugInfo] = useState<string>("");
  // const [annotations, setAnnotations] = useState<string[]>(initialAnnotations ?? []);

  const annotations = JSON.parse(
    (searchParams.get("annotations") ?? "").replace(/'/g, '"'),
  );

  return (
    annotations.length > 0 && (
      <DocumentRenderer
        citedSources={annotations.map(
          (source: string) => new ParsedAnnotation(source),
        )}
      />
    )
  );
};

export default DocumentViewer;
