import { useEffect } from "react";
import { supabase } from "../../api";
import { useAppDispatch } from "../../app/hooks";
import { setSession, setUser } from "../../app/globals/userSlice";
import { useGetAuthStateQuery, userApi } from "../../app/globals/userApiSlice";

//  Custom hook that manages and returns the current Supabase authentication session and user and sets it in userSlice.

function useSupabaseSession() {
  const dispatch = useAppDispatch();

  // get session and store it in cache
  const { data: session, isFetching: fetchingSession } = useGetAuthStateQuery();
  const userId = session?.user?.id;
  // get user info and store it in cache
  const {} = userApi.endpoints.getUserById.useQuerySubscription(userId ?? "", {
    skip: !userId,
  });

  // const fetchUserData = async (userId: string) => {
  //   try {
  //     const { data: user, error } = await supabase
  //       .from("users")
  //       .select("*")
  //       .eq("id", userId)
  //       .single();

  //     if (error) {
  //       console.error("Error fetching user data:", error);
  //       dispatch(setUser(undefined));
  //     } else {
  //       dispatch(setUser(user));
  //     }
  //   } catch (error) {
  //     console.error("Unexpected error:", error);
  //     dispatch(setUser(undefined));
  //   }
  // };

  // useEffect(() => {
  //   supabase.auth.getSession().then(({ data: { session } }) => {
  //     dispatch(setSession(session));
  //     if (session?.user?.id) {
  //       fetchUserData(session.user.id);
  //     }
  //   });

  //   const {
  //     data: { subscription },
  //   } = supabase.auth.onAuthStateChange((_event, session) => {
  //     dispatch(setSession(session));

  //     if (session?.user?.id) {
  //       fetchUserData(session.user.id);
  //     }
  //   });

  //   return () => subscription.unsubscribe();
  // }, []);
}

export default useSupabaseSession;
