import React, { useMemo, useState } from "react";
import { Toggle } from "@fluentui/react";
import BarChartSequence from "../../../../components/Charts/BarChartSequence";
import BoxPlot from "../../../../components/Charts/BoxPlot";

type Props = {
  tickerText: string;
  barChartData: any;
  boxPlotsData: any;
  medianTickerValue: number;
  defaultBarChart?: boolean;
};

const ChartTypeToggle = React.memo(
  ({
    tickerText,
    barChartData,
    boxPlotsData,
    medianTickerValue,
    defaultBarChart = false,
  }: Props) => {
    const [toggleState, setToggleState] = useState(defaultBarChart);

    // Memoize chart data if the data doesn't change frequently
    const memoizedBarChartData = useMemo(() => barChartData, [barChartData]);
    const memoizedBoxPlotsData = useMemo(() => boxPlotsData, [boxPlotsData]);

    const handleToggle = () => {
      setToggleState((prev) => !prev);
    };

    return (
      <>
        <Toggle
          defaultChecked={toggleState}
          onChange={handleToggle}
          label={"Chart Type"}
          onText="Bar Chart"
          offText="Box Plot"
        />

        {toggleState && <BarChartSequence data={memoizedBarChartData} />}

        {!toggleState && (
          <BoxPlot
            boxplots={memoizedBoxPlotsData}
            horizontal={true}
            medianTicker={medianTickerValue}
            tickerText={tickerText}
          />
        )}
      </>
    );
  },
);

export default ChartTypeToggle;
