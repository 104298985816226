import React from "react";
import Button from "../Buttons/Button";
import { MoreHorizontal24Regular } from "@fluentui/react-icons";

type Props = {
  trigger?: JSX.Element;
  onClick: () => void;
  isOpen?: boolean;
};

export default function DropdownTrigger({
  onClick,
  trigger,
  isOpen = false,
}: Props) {
  return trigger ? (
    React.cloneElement(trigger, {
      onClick: onClick,
      "aria-haspopup": "true",
      "aria-expanded": isOpen,
    })
  ) : (
    <Button
      icon={{ icon: <MoreHorizontal24Regular /> }}
      onClick={onClick}
      ariaLabel="Open dropdown"
      variant="ghost"
    />
  );
}
