import React, { useState } from "react";
import { Navigation24Filled } from "@fluentui/react-icons";
import OverlayDrawer from "../../ui/Drawer/OverlayDrawer";
import Button from "../../ui/Buttons/Button";
import { toggleVerticalBodyScroll } from "../../../helpers/toggleVerticalBodyScroll";
import { userApi } from "../../../app/globals/userApiSlice";
import userDropdownOptions from "../userDropdownOptions";
import OptionsRenderer from "../../ui/DropdownMenu/OptionsRenderer";

export default function ProfileDrawer() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { data: session } = userApi.endpoints.getAuthState.useQueryState();
  const userId = session?.user?.id;
  const { data: userData } = userApi.endpoints.getUserById.useQueryState(
    userId ?? "",
  );
  const firstName = userData?.first_name;

  const title =
    firstName || session?.user?.email
      ? `Hello, ${firstName ?? session?.user?.email ?? ""}`
      : "Menu";

  const { options } = userDropdownOptions();

  const toggleDrawer = () => {
    toggleVerticalBodyScroll(!isDrawerOpen);
    setIsDrawerOpen((prev) => !prev);
  };

  const handleOptionClick = (onClick: () => void) => {
    toggleDrawer();
    onClick();
  };

  const customDrawerTrigger = (
    <Button
      ariaLabel={
        isDrawerOpen ? "Close navigation menu" : "Open navigation menu"
      }
      onClick={toggleDrawer}
      variant="ghost"
      icon={{
        icon: <Navigation24Filled />,
        className: "text-white",
        rounded: true,
      }}
    />
  );
  return (
    <OverlayDrawer
      isOpen={isDrawerOpen}
      trigger={customDrawerTrigger}
      toggleDrawer={toggleDrawer}
      side="right"
      title={title}
      width={280}
      hasBackdrop
      closeOnClickOutside
    >
      <div className="px-2 py-4">
        <OptionsRenderer
          darkBg={true}
          options={options.slice(1)}
          onOptionSelect={handleOptionClick}
        />
      </div>
    </OverlayDrawer>
  );
}
