import React, { useState, useEffect } from "react";
import Tabs, { TabType } from "../../../../components/ui/Buttons/Tabs";
import Upload from "./Upload";
import Files from "./Files";
import { FooterActionsType } from "../../components/FooterBtns";
import { useAdminFileUploadMutation } from "../../../ReportGenerator/AddressSearchSection/AddressSearchSectionMutationsApiSlice";
import { toast } from "react-toastify";
import ProgressBar from "../../../../components/ui/ProgressBar/ProgressBar";
import { twMerge } from "tailwind-merge";
import useIsDemo from "../../../../hooks/useIsDemo";
import { useAppDispatch } from "../../../../app/hooks";
import { addFakeDocument } from "../../../_Demo/demoSlice";

export type TabKey = "upload" | "files";

export type BYODManagerProps = {
  initialTab?: TabKey;
};

type Props = BYODManagerProps & {
  setFooterActions: React.Dispatch<React.SetStateAction<FooterActionsType>>;
};

export default function BYODManager({
  initialTab = "upload",
  setFooterActions,
}: Props) {
  const dispatch = useAppDispatch();
  const [fakeLoading, setFakeLoading] = useState(false);
  const isDemo = useIsDemo();

  const [uploadProgress, setUploadProgress] = useState({
    completed: 0,
    total: 0,
  });

  const [
    adminFileUpload,
    { data, isLoading: fileUploadLoading, isSuccess, isError },
  ] = useAdminFileUploadMutation();

  const isLoading = fileUploadLoading || fakeLoading;

  const [tab, setTab] = useState(initialTab);
  const [pdfs, setPdfs] = useState<File[]>([]);

  const handleFileSelect = (files: FileList) => {
    // Convert FileList to Array for easier manipulation
    const filesArray = Array.from(files);
    setPdfs((prevPdfs) => [...prevPdfs, ...filesArray]);
  };

  const handleRemoveFile = (index: number) => {
    setPdfs((prevPdfs) => prevPdfs.filter((_, i) => i !== index));
  };

  const handleTabChange = (id: TabKey) => setTab(id);

  const tabsData: TabType<TabKey>[] = [
    { id: "upload", label: "Upload", isDisabled: isLoading },
    {
      id: "files",
      label: "Files",
      isDisabled: isLoading,
    },
  ];

  const handleFakeUpload = async (file: File) => {
    setFakeLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000)); // 1 second delay

    const generateFakeId = (fileName: string) => {
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return `${randomNum}_${fileName.replace(/\s+/g, "_").toLowerCase()}`;
    };

    const fakeFile = {
      created_at: new Date().toISOString(),
      id: generateFakeId(file.name),
      name: file.name,
    };

    dispatch(addFakeDocument(fakeFile));
    return { message: "File uploaded successfully" };
  };

  const handleFileUpload = async () => {
    const successfulUploads: { file: File; message: string }[] = [];
    const currentPdfs = pdfs;

    setUploadProgress({
      completed: 0,
      total: currentPdfs.length,
    });

    try {
      // Upload files one by one to handle individual successes/failures
      for (const file of pdfs) {
        try {
          const result = isDemo
            ? await handleFakeUpload(file)
            : await adminFileUpload({
                file: file,
              }).unwrap();

          successfulUploads.push({
            file,
            message: result.message ?? "",
          });

          setUploadProgress((prev) => ({
            ...prev,
            completed: prev.completed + 1,
          }));
        } catch (error) {
          console.error("error:", error);
        } finally {
          setFakeLoading(false);
        }
      }

      // Remove successfully uploaded files
      setPdfs((currentPdfs) =>
        currentPdfs.filter(
          (pdf) => !successfulUploads.some((result) => result.file === pdf),
        ),
      );

      // Show appropriate toast messages
      if (currentPdfs.length === successfulUploads.length) {
        toast.success("All files uploaded successfully");
        setTab("files");
      } else if (
        currentPdfs.length !== successfulUploads.length &&
        currentPdfs.length > 0
      ) {
        toast.error(
          `Failed to upload ${successfulUploads.length !== 0 ? "some" : ""} files. Please try again.`,
          { toastId: "uploadPDFFail" },
        );
      }
    } catch (error) {
      console.error("File upload failed", error);
      toast.error("An unexpected error occurred while uploading files");
    } finally {
      setUploadProgress({
        completed: 0,
        total: 0,
      });
    }
  };

  useEffect(() => {
    // Set footer actions based on current state
    if (tab === "upload") {
      setFooterActions?.({
        primaryBtn: {
          label: "Upload",
          action: handleFileUpload,
          isDisabled: pdfs.length === 0 || isLoading,
          isLoading: isLoading,
        },
        secondaryBtn: {
          isDisabled: isLoading,
        },
      });
    } else setFooterActions({});
  }, [tab, pdfs, isLoading, isSuccess, isError]);

  return (
    <div
      className={twMerge("flex max-h-full grow flex-col justify-start gap-3")}
    >
      <Tabs<TabKey>
        tabs={tabsData}
        activeTab={tab}
        onTabChange={handleTabChange}
      />

      {tab === "upload" && isLoading && (
        <div className="flex grow flex-col justify-center">
          <ProgressBar
            stats={{
              total: uploadProgress.total,
              completed: uploadProgress.completed,
            }}
          />
        </div>
      )}

      {tab === "upload" && !isLoading && (
        <Upload
          pdfs={pdfs}
          isLoading={isLoading}
          handleFileSelect={handleFileSelect}
          handleRemoveFile={handleRemoveFile}
        />
      )}

      {tab === "files" && <Files />}
    </div>
  );
}
