import React, { useState, useRef } from "react";
import useClickOutside from "../../../hooks/useClickOutside";
import { createPortal } from "react-dom";
import { PlacementType } from "../../../global/types";
import { useOptimalPlacement } from "../../../hooks/useOptimalPlacement ";
import useTriggerOnScroll from "../../../hooks/useTriggerOnScroll";
import { DropdownOption } from "./OptionsRenderer";
import OptionsRenderer from "./OptionsRenderer";
import DropdownTrigger from "./DropdownTrigger";

type DropdownProps = {
  trigger?: JSX.Element;
  options: DropdownOption[];
  placement?: PlacementType;
  maxWidth?: number;
};

const DropdownMenu = ({
  trigger,
  options,
  placement = "bottom-right",
  maxWidth = 360,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const { style } = useOptimalPlacement({
    containerRef: containerRef,
    floatingRef: menuRef,
    initialPlacement: placement,
    isVisible: isOpen,
  });

  const handleClose = () => setIsOpen(false);
  const handleToggle = () => setIsOpen((prev) => !prev);

  useClickOutside({
    ref: containerRef,
    onClickOutside: handleClose,
  });

  useTriggerOnScroll({ isOpen: isOpen, trigger: handleClose, threshold: 50 });

  const handleOptionClick = (onClick: () => void) => {
    setIsOpen(false);
    onClick();
  };

  return (
    <div className="inline-block" ref={containerRef}>
      <DropdownTrigger
        trigger={trigger}
        onClick={handleToggle}
        isOpen={isOpen}
      />

      {isOpen &&
        createPortal(
          <div
            style={{
              minWidth: "100px",
              maxWidth: maxWidth,
              width: `fit-content`,
              ...style,
            }}
            ref={menuRef}
            className="rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div className="p-1">
              <OptionsRenderer
                options={options}
                onOptionSelect={handleOptionClick}
              />
            </div>
          </div>,
          document.body,
        )}
    </div>
  );
};

export default DropdownMenu;
