import React from "react";
import { twMerge } from "tailwind-merge";
import { SpinnerIos20Filled } from "@fluentui/react-icons";

type BaseProps = {
  type?: "submit" | "reset" | "button";
  variant?: "primary" | "secondary" | "outline" | "ghost";
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: (arg?: any) => any;
  className?: string;
  icon?: {
    icon: JSX.Element;
    position?: "left" | "right";
    rounded?: boolean;
    className?: string;
  };
  maxWidth?: "fit" | "full" | number;
};

// Props when label is provided
type WithLabelProps = BaseProps & {
  label: string;
  ariaLabel?: string; // Optional when label exists
};

// Props when no label is provided
type WithoutLabelProps = BaseProps & {
  label?: never;
  ariaLabel: string; // Required when no label
};

// Union type that enforces our constraints
type ButtonProps = WithLabelProps | WithoutLabelProps;

export default function Button({
  type = "button",
  variant = "primary",
  isDisabled,
  isLoading,
  onClick,
  className,
  icon,
  maxWidth = "fit",
  label,
  ariaLabel,
}: ButtonProps) {
  const isGhost = variant === "ghost";
  const isOutline = variant.includes("outline");

  const variantClasses = (() => {
    switch (variant) {
      case "primary":
        return "bg-rf-very-bright-red enabled:hover:bg-rf-bright-red enabled:active:bg-rf-vibrant-red";
      case "secondary":
        return "bg-stone-400 enabled:hover:bg-stone-500 enabled:active:bg-stone-400";
      case "outline":
        return "border-[1px] border-rf-very-bright-red enabled:hover:bg-rf-very-bright-red enabled:hover:bg-opacity-10 enabled:active:bg-rf-very-bright-red enabled:active:bg-opacity-20";
      case "ghost":
        return "bg-transparent enabled:hover:bg-stone-400 enabled:active:bg-stone-500";
      default:
        return "bg-rf-very-bright-red enabled:hover:bg-rf-bright-red enabled:active:bg-rf-vibrant-red";
    }
  })();

  const handleBtnMaxWidth = (() => {
    if (typeof maxWidth === "number") return `${maxWidth}px`;

    switch (maxWidth) {
      case "fit":
        return "fit-content";
      case "full":
        return `100%`;
      default:
        return "auto";
    }
  })();

  const handleBtnMinWidth = (() => {
    if (typeof maxWidth === "number") return `${maxWidth}px`;

    if (label) return "fit-content";

    return "40px";
  })();

  const handleOnclick = () => {
    if (isDisabled || isLoading || !onClick) return;
    onClick();
  };

  const renderIcon =
    icon &&
    React.cloneElement(icon.icon, {
      className: twMerge(
        "leading-none shrink-0",
        label && "h-4 w-4",
        !label && "h-6 w-6",
        icon.className,
      ),
    });

  return (
    <button
      type={type}
      onClick={handleOnclick}
      disabled={isDisabled || isLoading}
      aria-label={ariaLabel}
      style={{
        maxWidth: handleBtnMaxWidth,
        width: "100%",
        minWidth: handleBtnMinWidth,
      }}
      className={twMerge(
        "relative block shrink-0 text-white transition-colors duration-300 disabled:cursor-not-allowed disabled:opacity-75",
        isGhost && "text-stone-950",
        isOutline && "text-rf-very-bright-red",
        "h-[40px] rounded-lg px-4",
        !label && "px-0",

        isLoading && "cursor-not-allowed",
        variantClasses,
        !label && icon && icon.rounded && "rounded-full",
        className,
      )}
    >
      <div
        className={twMerge(
          "flex items-center justify-center leading-none",
          isLoading ? "invisible" : "visible",

          !label && "justify-center",
          label && "gap-1",
        )}
      >
        {icon && (icon.position === "left" || !icon.position) ? (
          renderIcon
        ) : (
          <div />
        )}

        {label && (
          <p
            className={twMerge(
              "line-clamp-1 select-none break-all text-[16px] font-medium leading-normal",
            )}
          >
            {label}
          </p>
        )}

        {icon && icon.position === "right" ? renderIcon : <div />}
      </div>

      <div
        className={twMerge(
          "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] leading-none",
          !isLoading ? "invisible" : "visible",
        )}
      >
        <SpinnerIos20Filled className={twMerge("animate-spin")} />
      </div>
    </button>
  );
}
