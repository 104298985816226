import React, { useRef, useMemo } from "react";
import ReportSection from "../../../../../components/ui/ReportSection/ReportSection";
import KeyValueSection from "../../../../../components/ui/KeyValueSection/KeyValueSection";
import DocumentRenderer from "../../../AnswerToUserQuestion/components/DocumentRenderer/DocumentRenderer";
import { DocumentRendererHandle } from "../../../AnswerToUserQuestion/components/DocumentRenderer/DocumentRenderer";
import { ParsedAnnotation } from "../../../../../components/Answer";
import { parseAnswerToHtml } from "../../../../../components/Answer/AnswerParser";
import { ZoningUseClassificationTransformedPayloadType } from "../../../AddressSearchSection/AddressSearchSectionMutationsApiSlice";
import { answerToElements } from "../../../AnswerToUserQuestion/util/AnswerToElements";
import { CitationClickParams } from "../../../../../components/Answer/AnswerParser";

type Props = {
  response: ZoningUseClassificationTransformedPayloadType;
};

export default function ZoningSection({
  response: { title, answer, sources, additional_sources, issue },
}: Props) {
  const keysToExtract = ["Additional Notes", "Supporting Explanation"];

  const descrObjs = answer
    ? Object.fromEntries(
        Object.entries(answer).filter(([key]) => keysToExtract.includes(key)),
      )
    : {};

  const sumObjs = answer
    ? Object.fromEntries(
        Object.entries(answer).filter(([key]) => !keysToExtract.includes(key)),
      )
    : {};

  const DocumentRendererRef = useRef<DocumentRendererHandle>(null);

  const navigateToCitation = ({ path, event }: CitationClickParams): void => {
    const parsed = new ParsedAnnotation(path);
    if (parsed && DocumentRendererRef.current) {
      DocumentRendererRef.current.gotoAnnotation(parsed.getFirstAnnotation());
    }
  };

  const {
    parsedAnswerElements,
    sources: parsedSources,
    additionalSources: parsedAdditionalSources,
  } = useMemo(() => {
    let parsedAnswerElementsResult: {
      citations: string[];
      answerElements: JSX.Element[];
    } = { citations: [], answerElements: [] };
    let sourcesResult: ParsedAnnotation[] = [];
    let additionalSourcesResult: ParsedAnnotation[] = [];

    try {
      // Parse the answer data and generate the answer elements
      parsedAnswerElementsResult = parseAnswerToHtml({
        answer: JSON.stringify(answer), // Convert answer to a string
        isStreaming: false,
        onCitationClicked: navigateToCitation,
      });

      // Process sources and additional sources if available
      if (sources) {
        sources.forEach((source) => {
          sourcesResult.push(new ParsedAnnotation(source));
        });
      }
      if (additional_sources) {
        additional_sources.forEach((source) => {
          additionalSourcesResult.push(new ParsedAnnotation(source));
        });
      }
    } catch (e) {
      console.error("Error parsing answer data:", e);
      parsedAnswerElementsResult = parseAnswerToHtml({
        answer: "",
        isStreaming: false,
        onCitationClicked: navigateToCitation,
      });
    }

    return {
      parsedAnswerElements: parsedAnswerElementsResult,
      sources: sourcesResult,
      additionalSources: additionalSourcesResult,
    };
  }, [title, answer, sources, additional_sources, issue]);

  const reportSummary = sumObjs ? (
    <div>
      {Object.entries(sumObjs).map(([key, value], index) => {
        if (!value) return <></>;

        const { parsedAnswerElements } = answerToElements({
          isLoading: false,
          message: Array.isArray(value) ? value.join(", ") : value,
          navigateToCitation: navigateToCitation,
        });

        // Do not show annotations on title level
        return (
          <div key={index} className=" ">
            {index !== 0 && <p className="body-font font-bold">{key}</p>}

            <div className="citation-font">
              {parsedAnswerElements.answerElements.filter(
                (elem) => elem.type != "a",
              )}
            </div>
          </div>
        );
      })}
    </div>
  ) : undefined;

  const reportTitle = Object.entries(sumObjs)[0][0] ?? "";

  const renderDocRenderer =
    parsedAdditionalSources.length > 0 || parsedSources.length > 0;

  return (
    <ReportSection
      isInitiallyOpen={false}
      collapsible
      desc={reportSummary}
      childPadding={false}
      limitMaxHeight={false}
      title={reportTitle}
    >
      <div className="flex flex-col gap-3 rounded-b-[5px] bg-rf-very-light-gray sm:gap-5">
        <div className="mx-2.5 flex flex-col gap-2">
          {descrObjs &&
            Object.entries(descrObjs).map(([key, value], index) => {
              if (!value) return <></>;

              const { parsedAnswerElements } = answerToElements({
                isLoading: false,
                message: Array.isArray(value) ? value.join(", ") : value,
                navigateToCitation: navigateToCitation,
              });

              return (
                <div key={index} className=" ">
                  <p className="body-font font-bold">{key}</p>

                  <div className="citation-font">
                    {parsedAnswerElements.answerElements}
                  </div>
                </div>
              );
            })}
        </div>

        {renderDocRenderer && (
          <DocumentRenderer
            ref={DocumentRendererRef}
            citedSources={parsedSources}
            title={`${title ?? ""} Sources`}
            additionalSources={parsedAdditionalSources}
            collapsible={true}
            isInitiallyOpen={false}
            limitMaxHeight={false}
            headerClassName="bg-rf-light-gray rounded-t-none"
            bodyClassName="bg-rf-light-gray"
            activeColor="rf-very-light-gray"
          />
        )}
      </div>
    </ReportSection>
  );
}
