import { useScreenWidth } from "./useScreenWidth";
import useAdjustViewportHeight from "./useAdjustViewportHeight";
import useSupabaseSession from "./useSupabaseSession";
import useSubscriberGate from "./useSubscriberGate";
// Import other hooks

/**
 * useAppInitialization
 * Custom hook that initializes essential app-wide hooks on mount.
 * Use this initializer for top level on mount hooks
 */

export default function useAppInitialization() {
  useScreenWidth();
  useAdjustViewportHeight();
  useSupabaseSession();
  useSubscriberGate();
}
