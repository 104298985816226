import ReportSectionSkeleton from "../../../../components/ui/Loaders/ReportSectionSkeleton";

import AccordionSkeleton from "../../../../components/ui/Loaders/AccordionSkeleton";
import TextSkeleton from "../../../../components/ui/Loaders/TextSkeleton";
import { PermittingTimelineInfo } from "../../../../helpers/constants";
import AccordionSectionSpacer from "../../../../components/ui/Accordion/AccordionSectionSpacer";

export default function PermittingTimelineSkeleton() {
  return (
    <AccordionSkeleton title={PermittingTimelineInfo.title}>
      <AccordionSectionSpacer>
        <ReportSectionSkeleton title>
          <TextSkeleton lines={4} columns={2} lineWidth={30} />
        </ReportSectionSkeleton>

        <ReportSectionSkeleton title></ReportSectionSkeleton>
        <ReportSectionSkeleton title></ReportSectionSkeleton>
        <ReportSectionSkeleton title></ReportSectionSkeleton>
      </AccordionSectionSpacer>
    </AccordionSkeleton>
  );
}
