import React, { useState } from "react";
import SearchAddressAutocomplete from "../../features/ReportGenerator/AddressSearchSection/components/SearchForm/SearchAddressAutocomplete";
import TextArea from "../forms/TextArea/TextArea";
import { useAutocompletePredictions } from "../../features/ReportGenerator/AddressSearchSection/hooks/useAutocompletePredictions";
import { useAutocompleteServices } from "../../features/ReportGenerator/AddressSearchSection/hooks/useAutocompleteServices";
import { addressSearchApi } from "../../features/ReportGenerator/AddressSearchSection/addressSearchSectionApiSlice";
import { Save20Regular, SpinnerIos20Regular } from "@fluentui/react-icons";
import { twMerge } from "tailwind-merge";
import { userApi } from "../../app/globals/userApiSlice";

type Props = {
  address: string;
  setAddress: (address: string) => void;
  setCoords: (coords: { lat: number; lng: number }) => void;
  loading?: boolean;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
};

export default function SearchAddress({
  address,
  setAddress,
  setCoords,
  loading,
  inputValue,
  setInputValue,
}: Props) {
  const { data: session } = userApi.endpoints.getAuthState.useQueryState();

  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);

  const userId = session ? session.user.id : "";

  const { isLoading } =
    addressSearchApi.endpoints.fetchUserAddresses.useQueryState(userId);

  const { autocompleteService, placesService } = useAutocompleteServices();
  const predictions = useAutocompletePredictions(
    inputValue,
    autocompleteService,
  );

  const disableLoading = isLoading || loading;
  const disableBtn = !address || address?.length === 0;

  const handleInputChange = (inputValue: string) => {
    const input = inputValue.trim();

    setInputValue(inputValue);
    if (input.length > 0) {
      setIsAutocompleteOpen(true);
    } else {
      setIsAutocompleteOpen(false);
    }
  };

  const onBlur = () => {
    if (inputValue !== address && address && address?.length > 0)
      handleInputChange(address);
  };

  const handleInputFocus = () => {
    if (inputValue.length > 0) {
      setIsAutocompleteOpen(true);
    }
  };

  const handleCloseAutocomplete = () => setIsAutocompleteOpen(false);
  const handleSelectAddress = (
    place: google.maps.places.AutocompletePrediction,
  ) => {
    handleInputChange(place.description);
    setAddress(place.description);
    setIsAutocompleteOpen(false);
    getPlaceDetails(place.place_id);
  };

  const getPlaceDetails = (placeId: string) => {
    if (!placesService.current) return;

    const request = {
      placeId,
      fields: ["geometry.location"],
    };

    placesService.current.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && place) {
        const location = place.geometry?.location;
        if (location) setCoords({ lat: location.lat(), lng: location.lng() });
      } else {
        console.error("Error fetching place details:", status);
      }
    });
  };

  return (
    <div className="relative">
      <TextArea
        placeholder="Enter address here"
        roundBottom={
          !isAutocompleteOpen ||
          (isAutocompleteOpen && predictions.length === 0)
        }
        value={inputValue}
        onFocus={handleInputFocus}
        onBlur={onBlur}
        onChange={handleInputChange}
        disabled={disableLoading}
        className="pr-10"
      />
      <button
        disabled={disableLoading || disableBtn}
        type="submit"
        className={twMerge(
          "absolute right-0 top-0 h-[60px] rounded-full rounded-l-[30px] pl-2 pr-3 disabled:text-rf-light-gray",
          !disableLoading &&
            !disableBtn &&
            "duration-300 hover:bg-rf-very-light-gray active:bg-rf-light-gray",
        )}
      >
        {disableLoading ? (
          <SpinnerIos20Regular className="animate-spin" />
        ) : (
          <Save20Regular />
        )}
      </button>

      <SearchAddressAutocomplete
        predictions={predictions}
        address={inputValue}
        isOpen={isAutocompleteOpen}
        closeDropdown={handleCloseAutocomplete}
        onPlaceSelect={handleSelectAddress}
      />
    </div>
  );
}
