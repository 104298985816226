import { Link } from "react-router-dom";
import { Person24Filled } from "@fluentui/react-icons";
import ProfileDrawer from "./ProfileDrawer";
import { userApi } from "../../../app/globals/userApiSlice";

export default function MobileNav() {
  const { data: session } = userApi.endpoints.getAuthState.useQueryState();

  return (
    <header
      className={
        "z-10 flex h-[60px] flex-row items-center justify-between bg-rf-dark-brown px-2.5 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]"
      }
      role={"banner"}
    >
      <div className={"text-transparent"}>
        <Person24Filled />
      </div>

      <Link
        to="/"
        className={`header-font text-nowrap font-bold text-white`}
        aria-label="Home"
      >
        redflag
      </Link>

      {session ? <ProfileDrawer /> : <div />}
    </header>
  );
}
