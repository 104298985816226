import React, { useEffect, useState } from 'react';
import { UserSubscriptionCrud } from "./UserSubscriptionCrud";
import {SubscriptionData, User} from "../../../api/db"; // Adjust the path accordingly

const UserSubscriptionForm: React.FC<{ userId: string }> = ({ userId }) => {
    const [user, setUser] = useState<User | null>();
    const [subscription, setSubscription] = useState<SubscriptionData | null>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const crud = new UserSubscriptionCrud();

    // Fetch the user and subscription data when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const fetchedUser = await crud.getUser(userId);
                const fetchedSubscription = await crud.getSubscriptionsForUser(userId);
                setUser(fetchedUser);
                setSubscription(fetchedSubscription ? fetchedSubscription[0] : null);
            } catch (err) {
                setError('Failed to load data');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [userId]);

    // Handle form input changes for user
    const handleUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUser(prev => prev ? { ...prev, [name]: value } : null);
    };

    // Handle form input changes for subscription
    const handleSubscriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSubscription(prev => prev ? { ...prev, [name]: value } : null);
    };

    // Handle form submission
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        try {
            if (user) {
                await crud.updateUser(userId, user);
            }

            if (subscription && subscription.id) {
                await crud.updateSubscription(subscription.id, subscription);
            }

            alert('Data updated successfully');
        } catch (err) {
            setError('Failed to update data');
        }
    };

    const handleCreateSubscription = async () => {
        setError(null);
        try {
            const newSubscription: Partial<SubscriptionData> = {
                expiration: new Date().toISOString(), // Or any default expiration date
                user_id: userId,
            };

            await crud.createSubscription(newSubscription);

            // Refresh the subscription data
            const fetchedSubscription = await crud.getSubscriptionsForUser(userId);
            setSubscription(fetchedSubscription ? fetchedSubscription[0] : null);
        } catch (err) {
            setError('Failed to create subscription');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <form onSubmit={handleSubmit}>
            <h2>Edit User</h2>
            <div>
                <label>First Name:</label>
                <input
                    type="text"
                    name="first_name"
                    value={user?.first_name || ''}
                    onChange={handleUserChange}
                />
            </div>
            <div>
                <label>Last Name:</label>
                <input
                    type="text"
                    name="last_name"
                    value={user?.last_name || ''}
                    onChange={handleUserChange}
                />
            </div>
            <div>
                <label>Organization:</label>
                <input
                    type="text"
                    name="organization"
                    value={user?.organization || ''}
                    onChange={handleUserChange}
                />
            </div>

            <h2>Edit Subscription</h2>
            {subscription ? (
                <>
                    <div>
                        <label>Expiration:</label>
                        <input
                            type="date"
                            name="expiration"
                            value={subscription.expiration ? subscription.expiration.substring(0, 10) : ''}
                            onChange={handleSubscriptionChange}
                        />

                        <span className={"mx-2"}>{new Date(subscription.expiration) < new Date() ? "Expired" : "Active" }</span>
                    </div>
                </>
            ) : (
                <div>
                    <p>No subscription data found for this user.</p>
                    <button type="button" onClick={handleCreateSubscription}>
                        Create Subscription
                    </button>
                </div>
            )}

            <button type="submit">Save Changes</button>
        </form>
    );
};

export default UserSubscriptionForm;
