import React, { useRef } from "react";
import { twMerge } from "tailwind-merge";
import { StoredAddress } from "../../../../../api/userConfig";
import { addressSearchApi } from "../../addressSearchSectionApiSlice";
import useClickOutside from "../../../../../hooks/useClickOutside";
import useArrowKeyNavigation from "../../../../../hooks/useArrowKeyNavigation";
import { userApi } from "../../../../../app/globals/userApiSlice";

type Props = {
  className?: string;
  onAddressSelect: (address: StoredAddress) => void;
  isOpen: boolean;
  closeDropdown: () => void;
};

export default function SearchAddressSaved({
  className,
  onAddressSelect,
  isOpen,
  closeDropdown,
}: Props) {
  const { data: session } = userApi.endpoints.getAuthState.useQueryState();
  const userId = session ? session.user.id : "";

  const { data: savedAddressData } =
    addressSearchApi.endpoints.fetchUserAddresses.useQueryState(userId);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const addressRefs = useRef<(HTMLButtonElement | null)[]>([]); // Refs to each address button

  const handleClickOutsde = () => {
    setFocusedIndex(-1);
    closeDropdown();
  };

  // Close dropdown on outside click
  useClickOutside({
    ref: dropdownRef,
    onClickOutside: handleClickOutsde,
    ignoredIds: ["open-dropdown-button"],
  });

  // Handle keydown events for arrow navigation
  const { focusedIndex, setFocusedIndex } = useArrowKeyNavigation({
    isOpen,
    items: savedAddressData ?? [],
    onSelect: onAddressSelect,
  });

  if (!savedAddressData || !isOpen || savedAddressData.length == 0)
    return <></>;

  return (
    <div
      ref={dropdownRef}
      className={twMerge("searchTextAreaDropdown", className)}
    >
      <p className="body-font border-t border-rf-light-gray px-[20px] pb-1 pt-2 font-semibold">
        Saved Addresses
      </p>

      <ul className="thin-scrollbar max-h-[400px] w-full overflow-y-auto">
        {savedAddressData.map((address, index) => (
          <li key={address.id} className="w-full">
            <button
              ref={(el) => (addressRefs.current[index] = el)} // Set ref to each button
              className={twMerge(
                "line-clamp-1 w-full cursor-pointer break-all rounded-md px-[20px] py-1 text-left duration-300 hover:bg-rf-light-gray",
                focusedIndex === index && "bg-rf-light-gray", // Highlight the focused item
              )}
              onMouseDown={() => {
                setFocusedIndex(-1);
                onAddressSelect(address);
              }}
            >
              <p className="body-font">{address.address}</p>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
