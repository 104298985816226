import { KeyValueContentType } from "../../../../../components/ui/KeyValueSection/KeyValueSection";
import { SectionType } from "../../../helpers/renderers";
import { ZoningUseClassificationTransformedPayloadType } from "../../../../ReportGenerator/AddressSearchSection/AddressSearchSectionMutationsApiSlice";

export const zoningUseClassificationData: ZoningUseClassificationTransformedPayloadType[] =
  [
    {
      preview: "Varies",
      answer: {
        "Permitted Use":
          "Varies: Residential uses are permitted, with commercial uses allowed on the ground floor in some zoning districts[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-14]][[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-18]][[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-19]].",
        "Supporting Explanation":
          "The RD1.5-1-O zone permits residential uses, and specific plans and overlays may allow for commercial uses on the ground floor[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-14]][[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-18]][[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-19]].",
        "Additional Notes":
          "Higher residential densities are located near commercial centers and major bus routes[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-14]][[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-18]][[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-19]]. Mixed-use developments are supported in commercial zones[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-14]][[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-18]][[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-19]].",
      },
      sources: [
        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-14",
        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-18",
        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-19",
      ],
      additional_sources: [],
      issue:
        "Removed incorrect sources [[supplemental_O.pdf?annotation=file-supplemental_O_pdf-737570706C656D656E74616C5F4F2E706466-page-0]], [[supplemental_O.pdf?annotation=file-supplemental_O_pdf-737570706C656D656E74616C5F4F2E706466-page-1]] as they do not pertain to permitted uses in RD1.5-1-O. Updated citations to relevant sources [[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-14]], [[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-18]], [[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-19]] to accurately reflect permitted uses.",
    },
    {
      preview: "Varies",
      answer: {
        "Conditional Use": "Varies",
        "Supporting Explanation":
          "Conditional uses for the RD1.5-1-O zone include child care facilities, community centers, and religious institutions, all subject to review[[venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-14]][[venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-27]]. Other conditional uses include aquaria, observatories, planetaria, zoos, high voltage transmission lines, and any use set forth in Section 12.04.05 B.1. when located on land which includes a lake, river or stream, or is designated as an historic or cultural landmark[[conditional_use_permits.pdf?annotation=file-conditional_use_permits_pdf-636F6E646974696F6E616C5F7573655F7065726D6974732E706466-page-13]].",
        "Additional Notes":
          "Conditional uses in the Venice Community Plan area include residential care facilities, with requirements for property size and parking ratios[[venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-14]][[venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-27]]. In the Venice Coastal Zone Specific Plan, conditional uses permitted include automotive repair, outdoor dining, and event venues, all subject to review[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-11]][[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-14]]. The Venice Local Coastal Plan allows open air sales on private property through the City's conditional use permit/coastal development permit procedure. Uses such as open space, habitat management, controlled nature study and interpretation, and passive public recreation are encouraged and promoted adjacent to the canals and lagoon areas[[venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-201]][[venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-471]].",
      },
      sources: [
        "conditional_use_permits.pdf?annotation=file-conditional_use_permits_pdf-636F6E646974696F6E616C5F7573655F7065726D6974732E706466-page-13",
        "venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-14",
        "venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-27",
        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-11",
        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-14",
        "venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-201",
        "venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-471",
      ],
      additional_sources: [],
      issue:
        "Removed incorrect citations [[supplemental_O.pdf?annotation=file-supplemental_O_pdf-737570706C656D656E74616C5F4F2E706466-page-0]], [[supplemental_O.pdf?annotation=file-supplemental_O_pdf-737570706C656D656E74616C5F4F2E706466-page-1]] for child care facilities, community centers, and religious institutions. Correct sources are [[venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-14]], [[venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-27]].",
    },
    {
      preview: "5-15 feet",
      answer: {
        "Front Setback":
          "5-15 feet[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-18]][[general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-73]]",
        "Side Setback":
          "5 feet[[general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-73]]",
        "Rear Setback":
          "5 feet[[general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-73]]",
        "Supporting Explanation":
          "The front setback requirement for the property in the RD1.5-1-O zone is generally 15 feet[[general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-73]]. However, the Venice Coastal Zone Specific Plan allows for a minimum front setback of 5 feet[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-18]].",
        "Additional Notes":
          "Ground level patios, decks, landscaping, and railings, walls, and fences that do not exceed six feet in height may encroach into this setback, provided they observe a setback of one foot[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-18]].",
      },
      sources: [
        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-18",
        "general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-73",
      ],
      additional_sources: [],
      issue:
        "Removed citation to 'supplemental_O.pdf?annotation=file-supplemental_O_pdf-737570706C656D656E74616C5F4F2E706466-page-0' as it was irrelevant to front setback requirements.",
    },
    {
      preview: "Varies: 450 - 600 square feet",
      answer: {
        "Open Area Requirement":
          "Varies: 450 - 600 square feet depending on conditions[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-17]][[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-13]][[venice_coastal_specific_plan.pdf?annotation=file-venice_coastal_specific_plan_pdf-76656E6963655F636F617374616C5F73706563696669635F706C616E2E706466-page-12]][[venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-185]]",
        "Supporting Explanation":
          "The open space requirements for properties in the Venice Coastal Zone vary depending on the specific subarea and type of development. For single-family residential lots, an open, permeable yard of at least 450 square feet for a 30-foot wide lot, and at least 600 square feet for a 40-foot wide lot, must be maintained between the canal property line and the front of any structure[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-17]]. For duplexes and multi-family structures, yards are required to accommodate fire safety, open space, permeable land area for on-site percolation of stormwater, and on-site recreation[[venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-185]]. Specific requirements include a minimum of 450 square feet for a 30-foot wide lot and 600 square feet for a 40-foot wide lot in the Venice Canals subarea[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-17]]. Additionally, for properties along the Ballona Lagoon, a 25-foot setback from the property line nearest the lagoon is required, with an open, permeable yard of at least 450 square feet for a 30-foot wide lot and 600 square feet for a 40-foot wide lot[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-13]][[venice_coastal_specific_plan.pdf?annotation=file-venice_coastal_specific_plan_pdf-76656E6963655F636F617374616C5F73706563696669635F706C616E2E706466-page-12]].",
        "Additional Notes":
          "The open space requirements are influenced by the specific subarea within the Venice Coastal Zone and the type of development. The requirements are designed to ensure adequate open space for fire safety, stormwater percolation, and recreation[[venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-185]].",
      },
      sources: [
        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-17",
        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-13",
        "venice_coastal_specific_plan.pdf?annotation=file-venice_coastal_specific_plan_pdf-76656E6963655F636F617374616C5F73706563696669635F706C616E2E706466-page-12",
        "venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-185",
      ],
      additional_sources: [
        "venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-187",
        "venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-474",
        "venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-485",
      ],
    },
    {
      preview: "Varies: 30 feet - 45 feet",
      answer: {
        "Building Height":
          "Varies: 30 feet - 45 feet depending on conditions[[conditional_use_permits.pdf?annotation=file-conditional_use_permits_pdf-636F6E646974696F6E616C5F7573655F7065726D6974732E706466-page-56]][[general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-142]][[height_areas.pdf?annotation=file-height_areas_pdf-6865696768745F61726561732E706466-page-2]][[venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-88]][[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-118]]",
        "Supporting Explanation":
          "The building height varies due to different conditions specified in the Municipal Code, Venice Community Plan, and Venice Coastal Zone Specific Plan. Specific conditions include proximity to mean high tide lines, specific subareas, and roof types[[conditional_use_permits.pdf?annotation=file-conditional_use_permits_pdf-636F6E646974696F6E616C5F7573655F7065726D6974732E706466-page-56]][[general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-142]][[height_areas.pdf?annotation=file-height_areas_pdf-6865696768745F61726561732E706466-page-2]][[venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-88]][[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-118]].",
        "Additional Notes":
          "For example, within 60 horizontal feet of the mean high tide line of Ballona Lagoon, the height limit is 30 feet, but beyond 60 horizontal feet, one foot in additional height is permitted for each two additional horizontal feet to a maximum height of 45 feet[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-118]].",
      },
      sources: [
        "conditional_use_permits.pdf?annotation=file-conditional_use_permits_pdf-636F6E646974696F6E616C5F7573655F7065726D6974732E706466-page-56",
        "general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-142",
        "height_areas.pdf?annotation=file-height_areas_pdf-6865696768745F61726561732E706466-page-2",
        "venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-88",
        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-118",
      ],
      additional_sources: [
        "comprehensive_planning_program.pdf?annotation=file-comprehensive_planning_program_pdf-636F6D70726568656E736976655F706C616E6E696E675F70726F6772616D2E706466-page-2",
        "general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-147",
        "height_areas.pdf?annotation=file-height_areas_pdf-6865696768745F61726561732E706466-page-4",
        "height_areas.pdf?annotation=file-height_areas_pdf-6865696768745F61726561732E706466-page-7",
        "land_use_legislative_action.pdf?annotation=file-land_use_legislative_action_pdf-6C616E645F7573655F6C656769736C61746976655F616374696F6E2E706466-page-10",
        "summary_of_zoning_regulations.pdf?annotation=file-summary_of_zoning_regulations_pdf-73756D6D6172795F6F665F7A6F6E696E675F726567756C6174696F6E732E706466-page-233",
        "venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-25",
        "venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-86",
        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-12",
        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-25",
      ],
      issue:
        "Removed the minimum height of 18 feet as it was not applicable to zone RD1.5-1-O.",
    },
    {
      preview: "Varies: 0.5:1 - 1.5:1 depending on conditions.",
      answer: {
        FAR: "0.5:1 - 1.5:1 depending on conditions[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-24]][[venice_coastal_specific_plan.pdf?annotation=file-venice_coastal_specific_plan_pdf-76656E6963655F636F617374616C5F73706563696669635F706C616E2E706466-page-23]][[venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-199]]",
        "Supporting Explanation":
          "The floor area ratio (FAR) for the property in the RD1.5-1-O zone is limited by overlay zones such as the Venice Coastal Zone Specific Plan, which sets FARs to 0.5:1 for retail only, 1.0:1 for retail/office, and 1.5:1 for mixed-use retail and residential[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-24]][[venice_coastal_specific_plan.pdf?annotation=file-venice_coastal_specific_plan_pdf-76656E6963655F636F617374616C5F73706563696669635F706C616E2E706466-page-23]][[venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-199]].",
        "Additional Notes":
          "Specific overlays or conditions may apply, further altering these ratios[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-24]][[venice_coastal_specific_plan.pdf?annotation=file-venice_coastal_specific_plan_pdf-76656E6963655F636F617374616C5F73706563696669635F706C616E2E706466-page-23]][[venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-199]].",
      },
      sources: [
        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-24",
        "venice_coastal_specific_plan.pdf?annotation=file-venice_coastal_specific_plan_pdf-76656E6963655F636F617374616C5F73706563696669635F706C616E2E706466-page-23",
        "venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-199",
      ],
      additional_sources: [],
      issue:
        "Removed base zone FAR of 3:1 as overlays from the Venice Coastal Zone Specific Plan override the base zone FAR.",
    },
    {
      preview: "Varies: 2 floors - 5 floors",
      answer: {
        "Floors Allowed":
          "Varies: 2 floors - 5 floors depending on conditions[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-14]][[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-19]][[venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-180]]",
        "Supporting Explanation":
          "The number of floors allowed varies based on specific conditions such as location within the Venice Coastal Zone, roof type, and proximity to certain landmarks. For example, in the Ballona Lagoon East Bank subarea, the height limit is 30 feet within 60 horizontal feet of the Esplanade, allowing for approximately 3 floors, and 38 feet beyond that, allowing for up to 4 floors[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-14]]. In Oakwood, Milwood, and Southeast Venice subareas, height limits are 25 feet for flat roofs and 30 feet for varied roofs, equivalent to approximately 2-3 floors[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-19]]. In the Residential Development Standards, height limits of 30 feet within 60 horizontal feet and up to 38 feet beyond allow for up to 5 floors[[venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-180]].",
        "Additional Notes":
          "Additional conditions and specific overlays may further restrict or allow variations in building height.",
      },
      sources: [
        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-14",
        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-19",
        "venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-180",
      ],
      additional_sources: [],
      issue:
        "Corrected 'Floors Allowed' to reflect number of floors instead of heights and removed incorrect height sources.",
    },
    {
      preview: "Varies",
      answer: {
        "General Lot Restrictions":
          "1. Lot configuration or shape requirements: No specific shape requirements mentioned. 2. Floodplain restrictions: Not mentioned. 3. Environmental restrictions: Not mentioned. 4. Access and parking requirements: For any new construction or addition to a One-Family Dwelling on a lot fronting on a Substandard Hillside Limited Street, no building permit or grading permit shall be issued unless at least one-half of the width of the street(s) has been dedicated for the full width of the frontage of the lot to Standard Hillside Limited Street dimensions or to a lesser width as determined by the City Engineer [[general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-153]]. 5. Subdivision regulations: No person shall subdivide land into five or more parcels unless a Final Map has been recorded [[division_of_land_regulations.pdf?annotation=file-division_of_land_regulations_pdf-6469766973696F6E5F6F665F6C616E645F726567756C6174696F6E732E706466-page-0]]. 6. Building code or construction limitations: Buildings and structures extending more than 6 feet above natural ground level shall cover no more than 40% of the area of a lot [[general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-147]].",
        "Supporting Explanation":
          "The general lot restrictions for the property in the RD1.5-1-O zone include various requirements for lot configuration, access, parking, subdivision, and building limitations. These requirements are derived from multiple sources including the Municipal Code and specific zoning regulations [[general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-153]][[division_of_land_regulations.pdf?annotation=file-division_of_land_regulations_pdf-6469766973696F6E5F6F665F6C616E645F726567756C6174696F6E732E706466-page-0]][[general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-147]].",
        "Additional Notes":
          "Additional restrictions may apply based on specific conditions or overlays. For example, the Venice Coastal Zone Specific Plan includes additional height and setback requirements for properties within its jurisdiction [[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-14]][[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-18]].",
      },
      sources: [
        "general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-153",
        "division_of_land_regulations.pdf?annotation=file-division_of_land_regulations_pdf-6469766973696F6E5F6F665F6C616E645F726567756C6174696F6E732E706466-page-0",
        "general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-147",
        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-14",
        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-18",
      ],
      additional_sources: [
        "comprehensive_planning_program.pdf?annotation=file-comprehensive_planning_program_pdf-636F6D70726568656E736976655F706C616E6E696E675F70726F6772616D2E706466-page-2",
        "division_of_land_regulations.pdf?annotation=file-division_of_land_regulations_pdf-6469766973696F6E5F6F665F6C616E645F726567756C6174696F6E732E706466-page-48",
        "general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-132",
        "height_areas.pdf?annotation=file-height_areas_pdf-6865696768745F61726561732E706466-page-0-1",
        "height_areas.pdf?annotation=file-height_areas_pdf-6865696768745F61726561732E706466-page-7",
        "land_use_determination.pdf?annotation=file-land_use_determination_pdf-6C616E645F7573655F64657465726D696E6174696F6E2E706466-page-2",
      ],
      issue:
        "Removed the density limits section as it referenced an invalid source not following the 'annotation-nnnn' format.",
    },
    {
      preview: "Varies: 40 percent -45 percent",
      answer: {
        "Lot Coverage": "Varies: 40 percent -45 percent",
        "Supporting Explanation":
          "Lot coverage in the RD1.5-1-O zone is governed by the Municipal Code, which allows up to forty percent coverage for standard lots and up to forty-five percent for substandard lots based on specific conditions[[general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-147]].",
        "Additional Notes":
          "For substandard lots, defined as those less than fifty feet in width and less than five thousand square feet in area, buildings and structures shall cover no more than forty-five percent of the lot area[[general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-147]].",
      },
      sources: [
        "general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-147",
      ],
      additional_sources: [],
      issue:
        "Removed Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-11 as it does not contain relevant information on lot coverage.",
    },
    {
      preview: "N/A",
      answer: {
        "Total Allowable Building Area": "N/A",
        "Supporting Explanation":
          "No relevant information found in the provided sources.",
        "Additional Notes": "N/A",
      },
      sources: [],
      additional_sources: [],
      issue:
        "Previous answer incorrectly cited sources related to oil drilling districts (supplemental_O.pdf?annotation=file-supplemental_O_pdf-737570706C656D656E74616C5F4F2E706466-page-0, supplemental_O.pdf?annotation=file-supplemental_O_pdf-737570706C656D656E74616C5F4F2E706466-page-1). No relevant sources found for RD1.5-1-O zone in provided overlays.",
    },
    {
      preview: "Varies: 0.05 - 35 dwelling units per acre",
      answer: {
        "Allowable Density":
          "Varies: 0.05 - 35 dwelling units per acre depending on conditions [[summary_of_zoning_regulations.pdf?annotation=file-summary_of_zoning_regulations_pdf-73756D6D6172795F6F665F7A6F6E696E675F726567756C6174696F6E732E706466-page-130]]",
        "Supporting Explanation":
          "The RD1.5-1-O zone allows one dwelling unit per 1,500 square feet of lot area. However, the density can vary based on specific conditions such as slope density and other regulations [[division_of_land_regulations.pdf?annotation=file-division_of_land_regulations_pdf-6469766973696F6E5F6F665F6C616E645F726567756C6174696F6E732E706466-page-46]][[summary_of_zoning_regulations.pdf?annotation=file-summary_of_zoning_regulations_pdf-73756D6D6172795F6F665F7A6F6E696E675F726567756C6174696F6E732E706466-page-130]].",
        "Additional Notes":
          "The Municipal Code specifies that in Hillside Grading Areas, the density shall not exceed that allowed by the formula: D = (50 - S) / 35, where D is the maximum number of dwelling units per gross acre and S is the average natural slope of the land in percent [[division_of_land_regulations.pdf?annotation=file-division_of_land_regulations_pdf-6469766973696F6E5F6F665F6C616E645F726567756C6174696F6E732E706466-page-46]].",
      },
      sources: [
        "summary_of_zoning_regulations.pdf?annotation=file-summary_of_zoning_regulations_pdf-73756D6D6172795F6F665F7A6F6E696E675F726567756C6174696F6E732E706466-page-130",
      ],
      additional_sources: [
        "division_of_land_regulations.pdf?annotation=file-division_of_land_regulations_pdf-6469766973696F6E5F6F665F6C616E645F726567756C6174696F6E732E706466-page-46",
      ],
      issue:
        "Incorrect citation for 'Allowable Density'. Changed from [[division_of_land_regulations.pdf?annotation=file-division_of_land_regulations_pdf-6469766973696F6E5F6F665F6C616E645F726567756C6174696F6E732E706466-page-46]] to [[summary_of_zoning_regulations.pdf?annotation=file-summary_of_zoning_regulations_pdf-73756D6D6172795F6F665F7A6F6E696E675F726567756C6174696F6E732E706466-page-130]]. Removed irrelevant [[supplemental_O.pdf?annotation=file-supplemental_O_pdf-737570706C656D656E74616C5F4F2E706466-page-0]] from sources and explanations.",
    },
    {
      preview: "Varies: 2-3 spaces",
      answer: {
        Parking:
          "Varies: 2-3 spaces[[general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-1]][[general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-3]][[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-29]][[venice_coastal_specific_plan.pdf?annotation=file-venice_coastal_specific_plan_pdf-76656E6963655F636F617374616C5F73706563696669635F706C616E2E706466-page-36]]",
        "Supporting Explanation":
          "The parking requirements for the property at this parcel vary depending on the zoning classification and specific conditions. For residential zones, there shall be at least two automobile parking spaces on the same lot with each one-family dwelling. For commercial and industrial buildings, there shall be at least one automobile parking space for each 500 square feet of combined floor area. Specific plans or other zoning regulations may impose different requirements[[general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-1]][[general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-3]].",
        "Additional Notes":
          "For a single-family dwelling on a lot less than 40 feet in width, or less than 35 feet in width if adjacent to an alley, two spaces are required. For a single-family dwelling on a lot of 40 feet or more in width, or 35 feet or more in width if adjacent to an alley, three spaces are required. For multiple dwellings and duplexes on a lot less than 40 feet in width, or less than 35 feet in width if adjacent to an alley, two spaces per dwelling unit are required. For multiple dwellings and duplexes on a lot of 40 feet or more in width, or 35 feet or more in width if adjacent to an alley, two spaces per dwelling unit plus one guest parking space for each four or fewer units are required[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-29]][[venice_coastal_specific_plan.pdf?annotation=file-venice_coastal_specific_plan_pdf-76656E6963655F636F617374616C5F73706563696669635F706C616E2E706466-page-36]].",
      },
      sources: [
        "general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-1",
        "general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-3",
        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-29",
        "venice_coastal_specific_plan.pdf?annotation=file-venice_coastal_specific_plan_pdf-76656E6963655F636F617374616C5F73706563696669635F706C616E2E706466-page-36",
      ],
      additional_sources: [],
      issue:
        "Updated the minimum parking requirement from one to two spaces per dwelling unit based on sources [[general_provisions.pdf?annotation=file-general_provisions_pdf-67656E6572616C5F70726F766973696F6E732E706466-page-1]], [[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-29]], and [[venice_coastal_specific_plan.pdf?annotation=file-venice_coastal_specific_plan_pdf-76656E6963655F636F617374616C5F73706563696669635F706C616E2E706466-page-36]].",
    },
  ];

export const zoningInformationData: string[] = [
  "No zoning violations found on record.",
  "Prior permits include approval for a duplex onversion in 1980 and an ADU addition in 2021.",
];

export const specificPlanAreaData: SectionType = {
  title: "Applicable Specific Plan: Venice Coastal Zone Specific Plan",
  sections: [
    {
      title: "Designated Subarea:",
      paragraphs: [
        "Subarea Type: Residential Medium II.",
        "Purpose: To maintain a balance between preserving neighborhood character and accommodating moderate-density residential growth.",
      ],
    },
    {
      title: "Special Considerations:",
      paragraphs: [
        "Coastal Access Protection: Projects must not obstruct public access to coastal resources.",
        "View Corridor Preservation: Height and massing regulations are intended to preserve ocean and scenic views for surrounding properties.",
      ],
    },
  ],
};

export const generalPlanLandUseData: string =
  'The "Low Medium II Residential" overlay in Los Angeles outlines zoning regulations for specific residential areas. It aims to balance density with neighborhood character, allowing for moderate residential development. Key principles include maintaining a mix of housing types, such as duplexes and small apartment buildings, while preserving community aesthetics. The impact on building development includes restrictions on height and bulk, influencing architectural designs to fit within the neighborhood context. Real estate implications involve potential for increased property values due to desirable, controlled growth, while ensuring sustainable and cohesive urban planning.';

export const overlaysData: {
  link: { title: string; url: string };
  body: string;
}[] = [
  {
    link: {
      title: "Venice Community Plan",
      url: "https://planning.lacity.gov/plans-policies/community-plan-area/venice",
    },
    body: "The Venice Community Plan outlines a vision for the development and preservation of Venice, Los Angeles. It emphasizes sustainable growth, maintaining the unique character of the area, and enhancing public spaces. Key principles include promoting mixed-use development, preserving historic architecture, and improving transportation infrastructure. The plan aims to balance new construction with the conservation of Venice's eclectic style, impacting building development by encouraging designs that reflect the community's artistic heritage. Real estate strategies focus on affordable housing and preventing displacement, ensuring a diverse and inclusive neighborhood.",
  },
  {
    link: {
      title: "Venice Coastal Zone Specific Plan",
      url: "https://planning.lacity.gov/plans-policies/overlays/venice-coastal-zone",
    },
    body: "The Venice Coastal Zone Specific Plan is a regulatory framework guiding development in the Venice area of Los Angeles to preserve its unique coastal character. Key principles include maintaining public access to the beach, protecting natural resources, and ensuring new developments are compatible with the existing community. The plan impacts building development by imposing height restrictions, architectural guidelines, and density limits. It aims to balance growth with environmental preservation, influencing real estate values and promoting sustainable, community-focused urban planning.",
  },
  {
    link: {
      title: "Los Angeles Coastal Transportation Corridor Specific Plan",
      url: "https://planning.lacity.gov/plans-policies/overlays/coastal-transportation-corridor",
    },
    body: "The Los Angeles Coastal Transportation Corridor Specific Plan aims to manage transportation infrastructure and development within the coastal areas of Los Angeles. Key principles include improving traffic flow, enhancing public transit, and promoting sustainable transportation options. The plan impacts building development by imposing requirements for traffic mitigation measures and encouraging transit-oriented development. Architectural designs may need to incorporate features that support these goals, such as pedestrian-friendly elements. Real estate projects must consider these regulations to ensure compliance, potentially affecting project timelines and costs.",
  },
  {
    link: { title: "Venice Coastal Jurisdiction", url: "" },
    body: "The California Coastal Commission overlay in Los Angeles sets forth regulations to protect and manage the coastal resources. Key principles include preserving public access to the coast, protecting marine environments, and ensuring sustainable development. The document impacts building development by imposing strict guidelines on construction to minimize environmental damage, influencing architectural designs to be more eco-friendly, and affecting real estate by potentially limiting development opportunities in sensitive coastal areas. The intent is to balance growth with conservation, ensuring long-term ecological health and public enjoyment of the coastline.",
  },
];

export const easementsOrRightOfWays: SectionType = {
  title: "Recorded Easements:",
  sections: [
    {
      title: "Utility Easement (1980):",
      paragraphs: [
        "Location: Rear of the parcel.",
        "Purpose: Grants access to the local utility provider for maintenance of power and sewer lines.",
        "Impact: Limits development or construction in the designated easement area.",
      ],
    },

    {
      title: "Pedestrian Right-of-Way (1992):",
      paragraphs: [
        "Location: Along the western edge, adjacent to a public alley.",
        "Purpose: Allows public pedestrian access as part of the alley network.",
        "Impact: Restricts fencing or permanent structures that may block access.",
      ],
    },

    {
      title: "Drainage Easement (2008):",
      paragraphs: [
        "Location: Southeast corner of the parcel.",
        "Purpose: Ensures proper runoff management for adjacent properties and public stormwater systems.",
        "Impact: Requires unimpeded drainage flow; may limit landscaping or alterations in that area.",
      ],
    },

    {
      title: "Conclusion:",
      paragraphs: [
        "The parcel is subject to multiple easements affecting development and site use. These easements must be considered in any future building or landscaping plans to avoid conflicts and ensure compliance.",
      ],
    },
  ],
};
