import React, { useEffect, useState } from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { getHeaders, Overlay, Document } from "../../../api";
import { OverlayCrud } from "../overlays/OverlayCrud";
import { useLocation } from 'react-router-dom';
import { RegionCrud } from './RegionCrud';
import {DocumentCRUD} from "../documents/documentCrud";
import {DefaultButton, Icon, Link, Panel} from "@fluentui/react";

interface Region {
  id: string;
  name: string;
}


const RegionAdmin: React.FC = () => {
  const [regions, setRegions] = useState<Region[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<Region | undefined>(undefined);
  const [overlays, setOverlays] = useState<Overlay[]>([]);
  const [openOverlayId, setOpenOverlayId] = useState<string | null>(null);
  const [documents, setDocuments] = useState<{ [key: string]: Document[] }>({});
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const overlayService = new OverlayCrud();
  const location = useLocation();
  const regionCrud = new RegionCrud();
  const documentCrud = new DocumentCRUD();


  const loadRegions = async () => {
    try {
      const response = await fetch('/admin/regions', {
        headers: await getHeaders()
      });
      if (!response.ok) {
        throw new Error('Failed to fetch regions');
      }
      const data: Region[] = await regionCrud.getRegions();
      setRegions(data);
      return data;
    } catch (error) {
      console.error('Failed to fetch regions:', error);
      return [];
    }
  };

  const handleOverlayClick = async (overlay: Overlay) => {
    if (openOverlayId === overlay.id) {
      setOpenOverlayId(null);
    } else {
      setOpenOverlayId(overlay.id);
      if (!documents[overlay.id]) {
        try {
          const docs = await documentCrud.getAllDocumentsForOverlay(overlay.id);
          setDocuments(prevDocuments => ({
            ...prevDocuments,
            [overlay.id]: docs
          }));
        } catch (error) {
          console.error('Failed to fetch documents:', error);
        }
      }
    }
  };

  const loadOverlays = async (regionId: string) => {
    try {
      const data = await overlayService.getOverlays();
      setOverlays(data.filter(overlay => overlay.id.startsWith(regionId)));
    } catch (error) {
      console.error('Failed to fetch overlays:', error);
    }
  };

  useEffect(() => {
    const fetchDataAndSetRegion = async () => {
      const fetchedRegions = await loadRegions();
      const params = new URLSearchParams(location.search);
      const regionId = params.get('region');
      if (regionId) {
        const region = fetchedRegions.find(r => r.id === regionId);
        if (region) {
          setSelectedRegion(region);
          loadOverlays(region.id);
        }
      }
    };

    fetchDataAndSetRegion();
  }, [location]);

  const handleRegionChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    const region = regions.find(r => r.id === option?.key);
    setSelectedRegion(region);
    if (region) {
      loadOverlays(region.id);
    }

    // Update URL when region is changed
    const currentUrl = new URL(window.location.href);
    if (region) {
      currentUrl.searchParams.set('region', region.id);
    } else {
      currentUrl.searchParams.delete('region');
    }
    window.history.pushState({}, '', currentUrl.toString());
  };

  const groupOverlaysByType = (overlays: Overlay[]) => {
    const grouped: { [key: string]: Overlay[] } = {};
    overlays.forEach(overlay => {
      if (!grouped[overlay.type]) {
        grouped[overlay.type] = [];
      }
      grouped[overlay.type].push(overlay);
    });
    return grouped;
  };

  const groupedOverlays = groupOverlaysByType(overlays);

  return (
    <div className="p-4">
      <Dropdown
        placeholder="Select a region"
        options={regions.map((region) => ({
          key: region.id,
          text: region.name,
        }))}
        onChange={handleRegionChange}
        selectedKey={selectedRegion?.id}
        className="mb-4"
      />

      {selectedRegion && (
        <div>
          <h2 className="text-2xl font-bold mb-4">Overlays for {selectedRegion.name}</h2>
          {Object.entries(groupedOverlays).map(([type, typeOverlays]) => (
            <div key={type} className="mb-6">
              <h3 className="text-xl font-semibold mb-3">{type}</h3>
              <div className="grid grid-cols-1 gap-4">
                {typeOverlays.map((overlay) => (
                  <div key={overlay.id} className="bg-white p-4 rounded shadow">
                    <div className="flex justify-between items-center mb-2">
                      <h4 className="text-lg font-semibold">{overlay.name ?? overlay.id}</h4>
                      <DefaultButton
                        onClick={() => handleOverlayClick(overlay)}
                        iconProps={{ iconName: openOverlayId === overlay.id ? 'ChevronUp' : 'ChevronDown' }}
                      >
                        {openOverlayId === overlay.id ? 'Hide' : 'View'} Documents
                      </DefaultButton>
                    </div>
                    <p className="text-sm text-gray-600 mb-2">Type: {overlay.type}</p>
                    {openOverlayId === overlay.id && (
                      <div className="mt-4 pl-4 border-l-2 border-gray-200">
                        <h5 className="text-md font-semibold mb-2">Documents:</h5>
                        {documents[overlay.id] ? (
                          documents[overlay.id].length > 0 ? (
                            documents[overlay.id].map((document) => (
                              <div key={document.id} className="mb-2">
                                <a href={`/admin/documents?document=${document.id}`} className="text-blue-600 hover:underline flex items-center">
                                  <Icon iconName="Document" className="mr-2" />
                                  {document.name}
                                </a>
                              </div>
                            ))
                          ) : (
                            <p>No documents found for this overlay.</p>
                          )
                        ) : (
                          <p>Loading documents...</p>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

    export default RegionAdmin;
