import AccordionSkeleton from "../../../../components/ui/Loaders/AccordionSkeleton";
import TextSkeleton from "../../../../components/ui/Loaders/TextSkeleton";
import MapSkeleton from "../../../../components/ui/Loaders/MapSkeleton";
import { JurisdictionInfo } from "../../../../helpers/constants";

export default function JurisdictionSkeleton() {
  return (
    <AccordionSkeleton title={JurisdictionInfo.title}>
      <div className="flex w-full flex-col gap-3 lg:flex-row">
        <div className="w-full lg:w-[60%] xl:w-[50%]">
          <TextSkeleton lines={5} columns={2} lineWidth={70} />
        </div>
        {<MapSkeleton className="w-full lg:w-[40%] xl:w-[50%]" />}
      </div>
    </AccordionSkeleton>
  );
}
