import { createApi } from "@reduxjs/toolkit/query/react";
import { supabase } from "../../../api/db";
import { MotionInfo } from "../../../api";
import { baseQuery } from "../../../app/globals/baseQuery";

export type FetchMotionsParamsType = {
  currentPage: number;
  category: string;
  councilDistrict: number;
  perPage: number;
};

export const motionsApi = createApi({
  reducerPath: "motionsApi",
  baseQuery: baseQuery,
  tagTypes: ["Motions"],
  keepUnusedDataFor: 600,
  endpoints: (builder) => ({
    fetchMotions: builder.query<
      {
        total: number;
        perPage: number;
        lastPage: number;
        currentPage: number;
        motions: MotionInfo[];
      },
      FetchMotionsParamsType
    >({
      async queryFn({ currentPage, category, councilDistrict, perPage }) {
        try {
          let query = supabase
            .from("motions")
            .select("*", { count: "exact" })
            .range((currentPage - 1) * perPage, currentPage * perPage - 1);

          if (category) query = query.eq("category", category);

          if (councilDistrict)
            query = query.eq("council_district", councilDistrict);

          const { data: motions, count, error } = await query;

          if (error)
            return { error: { status: 500, statusText: error.message } };

          return {
            data: {
              motions: motions || [],
              total: count || 0,
              lastPage: Math.ceil((count || 0) / perPage),
              currentPage: currentPage,
              perPage: perPage,
            },
          };
        } catch (err: unknown) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error:
                (err instanceof Error ? err.message : "Unknown error") ||
                "Unknown error occurred",
              data: undefined,
            },
          };
        }
      },

      merge: (currentCacheData, incomingData, { arg }) => {
        if (arg.currentPage === 1) {
          currentCacheData.motions = incomingData.motions;
        } else {
          currentCacheData.motions = [
            ...currentCacheData.motions,
            ...incomingData.motions,
          ];
        }

        currentCacheData.currentPage = incomingData.currentPage;
        currentCacheData.lastPage = incomingData.lastPage;
        currentCacheData.perPage = incomingData.perPage;
        currentCacheData.total = incomingData.total;
      },

      forceRefetch: ({ currentArg, previousArg }) => {
        return (
          currentArg?.currentPage !== previousArg?.currentPage ||
          currentArg?.category !== previousArg?.category
        );
      },

      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { currentPage, category, councilDistrict } = queryArgs;
        return `${endpointName}-${category}-${councilDistrict}`;
      },
      providesTags: (result, error, { category, councilDistrict }) =>
        result
          ? [
              { type: "Motions", id: "LIST" },
              ...result.motions.map((motion) => ({
                type: "Motions" as const,
                id: motion.id,
              })),
              {
                type: "Motions" as const,
                id: `${category}-${councilDistrict}`,
              },
            ]
          : [{ type: "Motions", id: "LIST" }],
    }),
  }),
});

export const { useFetchMotionsQuery } = motionsApi;
