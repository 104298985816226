import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Session } from "@supabase/supabase-js";

type UserType = {
  created_at?: string;
  first_name?: string;
  id?: string;
  last_name?: string;
  organization?: null | string;
};

interface InitialState {
  session: Session | null;
  user?: UserType;
}

const initialState: InitialState = {
  session: null,
  user: {
    first_name: undefined,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<InitialState["session"]>) => {
      state.session = action.payload;
    },
    setUser: (state, action: PayloadAction<UserType | undefined>) => {
      state.user = action.payload;
    },
  },
});

export const { setSession, setUser } = userSlice.actions;
export default userSlice.reducer;
