import {
  ChecklistRequest,
  submitChecklist,
} from "../../../components/ChecklistBuilder/api";
import React, { useEffect, useState } from "react";
import { validateJsonFormat } from "../../../components/ChecklistBuilder/jsonValidation";
import {
  ChecklistOptions,
  QueryInput,
} from "../../../components/ChecklistBuilder";
import { supabase } from "../../../api";
import StoredReportView from "../../../components/ChecklistBuilder/StoredReportView";
import { useParams } from "react-router-dom";
import { Save20Filled } from "@fluentui/react-icons";

const MAX_CHECKLIST_ITEMS = 20;

interface Location {
  address?: string;
  lat?: number;
  lng?: number;
}

interface FormState {
  location: Location | null;
  intermediatePrompt?: string;
  finalizedPrompt?: string;
}

class StoredSearch {
  constructor(
    public id: number = 0,
    public query: string = "",
    public outputFormat: string = "",
    public title: string = "",
  ) {}

  static fromJson(json: any): StoredSearch {
    return new StoredSearch(
      json.id,
      json.query,
      json.output_format,
      json.title,
    );
  }

  toJson(): object {
    const idDef = this.id !== 0 ? { id: this.id } : {};

    return {
      ...idDef,
      query: this.query,
      output_format: this.outputFormat,
      title: this.title,
    };
  }
}

class ChecklistFetcher {
  async getChecklistName(checklistId: number): Promise<string> {
    let { data, error } = await supabase
      .from("checklists")
      .select("name")
      .eq("id", checklistId)
      .single();
    if (error) throw error;
    return data?.name ?? "";
  }

  async getChecklistItems(checklistId: number): Promise<StoredSearch[]> {
    if (checklistId === 0) {
      return [];
    }
    const { data, error } = await supabase.rpc(
      "get_stored_searches_for_checklist_id",
      {
        searched_checklist_id: checklistId,
      },
    );

    if (error) {
      console.error("Error fetching stored searches for checklist:", error);
      return [];
    }

    return (data as any[]).map(StoredSearch.fromJson);
  }

  async updateChecklistItems(
    items: StoredSearch[],
    checklistId: number,
    inOrder = false,
  ): Promise<StoredSearch[]> {
    try {
      const { data, error } = await supabase
        .rpc("upsert_stored_searches_and_link", {
          _id: items.map((item) => item.id),
          _query: items.map((item) => (item.query ? item.query : "")),
          _output_format: items.map((item) => item.outputFormat),
          _title: items.map((item) => (item.title ? item.title : "")),
          _checklist_id: checklistId,
        })
        .select();
      data?.map((itemId, idx) => {
        items[idx].id = itemId.id;
      });
      return items;
    } catch (error) {
      console.error("Error updating stored searches for checklist:", error);
      throw error; // Re-throw the error for the caller to handle
    }
  }

  async deleteChecklistItems(itemsToDelete: StoredSearch[]): Promise<void> {
    try {
      // Delete the entries from checklist_stored_searches
      const { error: unlinkError } = await supabase
        .from("checklist_stored_searches")
        .delete()
        .in(
          "stored_search_id",
          itemsToDelete.map((item) => item.id),
        );

      if (unlinkError) throw unlinkError;

      // Delete the StoredSearch items
      const { error: deleteError } = await supabase
        .from("stored_searches")
        .delete()
        .in(
          "id",
          itemsToDelete.map((item) => item.id),
        );

      if (deleteError) throw deleteError;
    } catch (error) {
      console.error("Error deleting stored searches for checklist:", error);
      throw error; // Re-throw the error for the caller to handle
    }
  }
}

interface ChecklistBuilderProps {
  create?: boolean;
}

function ChecklistBuilder({ create }: ChecklistBuilderProps) {
  const [queries, setQueries] = useState<StoredSearch[]>([new StoredSearch()]);

  const [formState, setFormState] = useState<FormState>({
    location: null,
    intermediatePrompt: "",
    finalizedPrompt: "",
  });

  const [response, setResponse] = useState<any>(undefined);
  const [reportId, setReportId] = useState<number>();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const { checklistIdParam } = useParams<{ checklistIdParam?: string }>();
  const [checklistId, setChecklistId] = useState<number>(
    create ? 0 : Number(checklistIdParam) || 25,
  );

  const [checklistName, setChecklistName] = useState<string>();

  useEffect(() => {
    if (!create && checklistIdParam == undefined) {
      setChecklistId(10); // default alpha checklist
      return;
    }
    setChecklistId(Number(checklistIdParam));
  }, [checklistIdParam]);

  const handleAddQuery = () => {
    if (queries.length < MAX_CHECKLIST_ITEMS) {
      setQueries([...queries, new StoredSearch()]);
    }
  };

  useEffect(() => {
    let fetcher = new ChecklistFetcher();
    fetcher.getChecklistItems(checklistId).then((items) => {
      setQueries(items);
      validateQueries();
    });
    fetcher.getChecklistName(checklistId).then(setChecklistName);
  }, [checklistId]);

  const handleQueryChange = (index: number, field: string, value: string) => {
    const newQueries = [...queries];
    const updatedSearch = new StoredSearch(
      newQueries[index].id,
      field === "query" ? value : newQueries[index].query,
      field === "outputFormat" ? value : newQueries[index].outputFormat,
      field === "title" ? value : newQueries[index].title,
    );
    newQueries[index] = updatedSearch;
    setQueries(newQueries);
    setError("");
  };

  const createChecklistIfNew = async () => {
    if (!create) return;
    const { data: rows, error } = await supabase
      .from("checklists")
      .insert({
        name: checklistName?.length ?? "New Checklist",
      })
      .select("id")
      .single();

    if (error) {
      console.error("Error creating checklist:", error);
      return 0;
    }
    const createdChecklistId = rows?.id || 0;
    // Set the URL to include the new checklist ID
    window.history.pushState({}, "", `/admin/checklist/${createdChecklistId}`);
    setChecklistId(createdChecklistId);
    create = false;
    return createdChecklistId;
  };

  const handleSaveChecklist = async () => {
    let fetcher = new ChecklistFetcher();
    let createdChecklistId = checklistId || (await createChecklistIfNew());

    const { error: error } = await supabase.from("checklists").upsert({
      id: checklistId,
      name: checklistName,
      prompt_overrides: {
        intermediate_prompt: formState.intermediatePrompt,
        finalized_prompt: formState.finalizedPrompt,
      },
    });

    fetcher
      .updateChecklistItems(queries, createdChecklistId, true)
      .then(setQueries);
  };

  const handleDeleteQuery = (index: number) => {
    if (queries.length > 1) {
      setQueries(queries.filter((_, i) => i !== index));
      setError("");
      let fetcher = new ChecklistFetcher();
      fetcher.deleteChecklistItems([queries[index]]).then((r) => {});
    }
  };

  const handleFormStateChange = (updates: Partial<FormState>) => {
    setFormState((prev) => {
      const newState = { ...prev, ...updates };
      // Ensure that location is properly updated
      if (updates.location) {
        newState.location = { ...prev.location, ...updates.location };
      }
      return newState;
    });
    setError("");
  };

  const validateQueries = (): boolean => {
    try {
      // Validate all queries have content
      const emptyQueries = queries.find(
        (q) => !q.query.trim() || !q.outputFormat.trim(),
      );
      if (emptyQueries) {
        throw new Error("All queries and formats must be filled out");
      }

      // Validate all JSON formats
      queries.forEach((q, index) => {
        try {
          validateJsonFormat(q.outputFormat);
        } catch (error) {
          throw new Error(
            `Query ${index + 1}: ${error instanceof Error ? error.message : "Invalid JSON format"}`,
          );
        }
      });

      return true;
    } catch (error) {
      setError(error instanceof Error ? error.message : "Validation failed");
      return false;
    }
  };

  const handleSubmit = async () => {
    setError("");

    if (!validateQueries()) {
      return;
    }
    setIsLoading(true);

    try {
      const checklistRequests: ChecklistRequest[] = queries.map((q) => ({
        query: q.query,
        format: q.outputFormat,
        title: q.title,
      }));

      const response = await submitChecklist(
        checklistRequests,
        [formState.location!.lat!, formState.location!.lng!],
        formState.location!.address,
        formState.intermediatePrompt,
        formState.finalizedPrompt,
      );
      let response_data: object[] = response.results.map((response) => {
        return {
          answer: JSON.parse(
            response?.choices[0]["message"]["content"] ?? "{}",
          ),
          id: 0,
        };
      });

      setReportId(response.report_id);

      setResponse(response_data);
    } catch (error) {
      setError(
        error instanceof Error ? error.message : "Failed to process queries",
      );
    } finally {
      setIsLoading(false);
    }
  };
  function swapOrder(bottomIndex: number) {
    setQueries((prevQueries) => {
      const newQueries = [...prevQueries];
      const temp = newQueries[bottomIndex];
      newQueries[bottomIndex] = newQueries[bottomIndex + 1];
      newQueries[bottomIndex + 1] = temp;
      return newQueries;
    });
  }

  return (
    <div className="min-h-screen bg-gray-50 px-4 py-12 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-4xl space-y-8">
        <div className="flex items-center justify-center space-x-4">
          <div className="flex items-center">
            <input
              type="text"
              value={checklistName ?? "Checklist Item Builder"}
              onChange={(e) => setChecklistName(e.target.value)}
              onBlur={() => {
                // You might want to add a function here to update the checklist name in the database
                // For example: updateChecklistNameInDB(checklistId, checklistName);
              }}
              className="border-b-2 border-transparent bg-transparent bg-white text-3xl font-bold text-gray-900 focus:border-blue-500 focus:outline-none"
            />
          </div>
        </div>
        <p className="text-center text-gray-600">
          Build your questions with JSON output formats
        </p>
        <ChecklistOptions
          formState={formState}
          onChange={handleFormStateChange}
          checklistId={checklistId}
        />

        <div className="space-y-6">
          {queries.map((query, index) => (
            <QueryInput
              key={index}
              query={query}
              index={index}
              onChange={handleQueryChange}
              onDelete={handleDeleteQuery}
              moveDown={
                index == queries.length - 1 ? undefined : () => swapOrder(index)
              }
              moveUp={index == 0 ? undefined : () => swapOrder(index - 1)}
            />
          ))}

          {queries.length < MAX_CHECKLIST_ITEMS && (
            <button
              onClick={handleAddQuery}
              className="flex w-full items-center justify-center gap-2 rounded-lg border-2 border-dashed border-gray-300 py-4 text-gray-600 transition-colors hover:border-blue-500 hover:text-blue-500"
            >
              {/*<PlusCircle size={20} />*/}
              Add Query
            </button>
          )}
        </div>

        {error && (
          <div className="border-l-4 border-red-500 bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                {/*<AlertCircle className="h-5 w-5 text-red-400" />*/}
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-center gap-2">
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className="rounded-lg bg-blue-600 px-8 py-3 font-medium text-white transition-colors hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          >
            {isLoading ? "Processing..." : "Run Checklist"}
          </button>
          <button
            className="rounded-lg bg-blue-500 p-2 text-white transition-colors hover:bg-blue-600"
            onClick={handleSaveChecklist}
            // disabled={loading}
          >
            <Save20Filled className="h-6 w-6"></Save20Filled>Save
          </button>
        </div>

        {response && !isLoading && (
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-900">Response</h2>

            {reportId && (
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  readOnly
                  value={`${window.location.origin}/admin/report/${reportId}`}
                  className="flex-grow rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                />
                <button
                  onClick={() =>
                    navigator.clipboard.writeText(
                      `${window.location.origin}/admin/report/${reportId}`,
                    )
                  }
                  className="rounded-md bg-blue-600 px-4 py-2 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Copy
                </button>
              </div>
            )}

            <StoredReportView propAnswers={response} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ChecklistBuilder;
