import { useEffect, useState, useRef } from "react";
import { isValidCoordinates } from "../../../../helpers/locations";
import { COORDS } from "../../../../helpers/constants";
import MapSkeleton from "../../../../components/ui/Loaders/MapSkeleton";

interface Props {
  className?: string;
  isLoading?: boolean;
  coordinates:
    | {
        lat: number;
        lng: number;
      }
    | undefined;
}

const StreetViewSearchMap = ({ className, coordinates, isLoading }: Props) => {
  const streetViewRef = useRef<HTMLDivElement>(null);
  const [panorama, setPanorama] =
    useState<google.maps.StreetViewPanorama | null>(null);
  const cordsExist = !!coordinates;
  const coordsValue = cordsExist ? coordinates : COORDS.california;

  // Calculate the heading (in degrees) between two coordinates
  const calculateHeading = (
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number,
  ) => {
    const dLng = lng2 - lng1;
    const y = Math.sin(dLng) * Math.cos(lat2);
    const x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLng);
    const heading = (Math.atan2(y, x) * 180) / Math.PI; // Convert to degrees
    return (heading + 270) % 360; // Ensure the heading is between 0-360
  };

  useEffect(() => {
    if (!streetViewRef.current) return;

    const panoramaInstance = new window.google.maps.StreetViewPanorama(
      streetViewRef.current,
      {
        position: { lat: coordinates?.lat ?? 0, lng: coordinates?.lng ?? 0 },
        pov: { heading: 0, pitch: 0 },
        zoom: 1,
        visible: cordsExist,
      },
    );

    setPanorama(panoramaInstance);

    if (!coordinates || !coordinates.lat || !coordinates.lng) {
      return;
    }

    // Use StreetViewService to find the actual camera position
    const streetViewService = new window.google.maps.StreetViewService();
    const radius = 50; // Search radius in meters

    let cameraPosition = panoramaInstance.getPosition();
    streetViewService.getPanorama(
      { location: { lat: coordinates.lat, lng: coordinates.lng }, radius },
      (data, status) => {
        if (
          status === window.google.maps.StreetViewStatus.OK &&
          data?.location?.latLng
        ) {
          cameraPosition = data.location.latLng;

          const cameraLat = cameraPosition.lat();
          const cameraLng = cameraPosition.lng();

          const heading = calculateHeading(
            cameraLat,
            cameraLng,
            coordsValue.lat,
            coordsValue.lng,
          );
          // Update Street View to point towards the property
          panoramaInstance.setPosition({
            lat: coordinates.lat,
            lng: coordinates.lng,
          });
          panoramaInstance.setPov({ heading, pitch: 0 });
          panoramaInstance.setVisible(true);
        }
      },
    );

    // Cleanup function
    return () => {
      setPanorama(null);
    };
  }, [coordinates, cordsExist, coordsValue.lat, coordsValue.lng]);

  if (isLoading)
    return (
      <MapSkeleton className="h-full shadow-[0px_0px_10px_0px_#00000040]" />
    );

  return (
    coordsValue &&
    isValidCoordinates(coordsValue) && (
      <div
        ref={streetViewRef}
        className="h-full w-full overflow-clip rounded-[10px] bg-rf-light-gray shadow-[0px_0px_10px_0px_#00000040]"
      />
    )
  );
};

export default StreetViewSearchMap;
