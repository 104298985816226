import { createApi } from "@reduxjs/toolkit/query/react";
import { supabase } from "../../api/db";
import { baseQuery } from "../../app/globals/baseQuery";
import { Session } from "@supabase/supabase-js";

type UserType = {
  created_at?: string;
  first_name?: string;
  id?: string;
  last_name?: string;
  organization?: null | string;
};

export const userApi = createApi({
  reducerPath: "userApi",
  tagTypes: ["Session", "User"],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getAuthState: builder.query<Session | null, void>({
      async queryFn() {
        try {
          const {
            data: { session },
          } = await supabase.auth.getSession();

          return { data: session };
        } catch (err: unknown) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error:
                (err instanceof Error ? err.message : "Unknown error") ||
                "Unknown error occurred",
              data: undefined,
            },
          };
        }
      },
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        try {
          // Wait for the initial query to resolve
          await cacheDataLoaded;

          // Set up the subscription to auth state changes
          const {
            data: { subscription },
          } = supabase.auth.onAuthStateChange((_event, session) => {
            // When auth state changes, update our cache with the new session
            updateCachedData(() => session);
          });

          // Clean up the subscription when the cache entry is removed
          await cacheEntryRemoved;
          subscription.unsubscribe();
        } catch {
          // If something went wrong, we don't need to do anything -
          // the query will automatically re-run if needed
        }
      },
      providesTags: ["Session"],
    }),

    // Get user by ID (useful for fetching other users' data)
    getUserById: builder.query<UserType, string>({
      async queryFn(userId) {
        try {
          const { data: user, error } = await supabase
            .from("users")
            .select("*")
            .eq("id", userId)
            .single();

          if (error) {
            return {
              error: {
                status: 500,
                statusText: error.message,
                data: error,
              },
            };
          }

          return { data: user };
        } catch (err: unknown) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: err instanceof Error ? err.message : "Unknown error",
              data: undefined,
            },
          };
        }
      },
      providesTags: () => ["User"],
    }),
  }),
});

export const { useGetUserByIdQuery, useGetAuthStateQuery } = userApi;
