import React from "react";
import { useEffect, useState } from "react";
import { supabase, User } from "../../../api/db";
import { useForm } from "react-hook-form";
import Button from "../../../components/ui/Buttons/Button";
import { userApi } from "../../../app/globals/userApiSlice";

type Props = {
  setSetup: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function UserSettingsForm({ setSetup }: Props) {
  const { data: session } = userApi.endpoints.getAuthState.useQueryState();

  const [loading, setLoading] = useState<boolean>(true);

  let user: User = {
    id: "",
    first_name: "",
    last_name: "",
    organization: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<User>({
    defaultValues: {
      first_name: "",
      last_name: "",
      organization: "",
    },
  });

  useEffect(() => {
    let ignore = false;
    async function getProfile() {
      try {
        setLoading(true);

        const { data: userData, error: userError } = await supabase
          .from("users")
          .select(
            `
                      first_name,
                      last_name,
                      organization,
                      id
                `,
          )
          .eq("id", session?.user.id);

        if (userError) {
          console.error("Error fetching user data:", userError);
          return;
        }
        if (!userData?.length) {
          setSetup(true);
        } else {
          setSetup(false);
          user = userData[0];
          reset(user); // Reset the form with user data
        }
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    getProfile();

    return () => {
      ignore = true;
    };
  }, [session, reset]);

  async function updateProfile(data: User) {
    setLoading(true);

    const updates = {
      id: session?.user.id,
      first_name: data.first_name,
      last_name: data.last_name,
      organization: data.organization,
    };

    const { error } = await supabase.from("users").upsert(updates);

    if (error) {
      alert(error.message);
    } else {
      // navigate('/'); // Navigate to the root route after successful update
    }
    setLoading(false);
  }
  return (
    <form
      onSubmit={handleSubmit(updateProfile)}
      className="mx-auto max-w-sm space-y-4"
    >
      <div>
        <label
          htmlFor="email"
          className="mb-2 block text-sm font-bold text-gray-700"
        >
          Email
        </label>
        <input
          id="email"
          type="text"
          value={session?.user.email}
          disabled
          className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none disabled:bg-gray-100"
        />
      </div>
      <div>
        <label
          htmlFor="first_name"
          className="mb-2 block text-sm font-bold text-gray-700"
        >
          First Name
        </label>
        <input
          {...register("first_name", { required: true })}
          id="first_name"
          type="text"
          className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none disabled:bg-gray-100"
        />
        {errors.first_name && (
          <p className="text-xs italic text-red-500">First name is required</p>
        )}
      </div>
      <div>
        <label
          htmlFor="last_name"
          className="mb-2 block text-sm font-bold text-gray-700"
        >
          Last Name
        </label>
        <input
          {...register("last_name", { required: true })}
          id="last_name"
          type="text"
          className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none disabled:bg-gray-100"
        />
        {errors.last_name && (
          <p className="text-xs italic text-red-500">Last name is required</p>
        )}
      </div>
      <div>
        <label
          htmlFor="organization"
          className="mb-2 block text-sm font-bold text-gray-700"
        >
          Organization
        </label>
        <input
          {...register("organization")}
          id="organization"
          type="text"
          disabled={true}
          className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none disabled:bg-gray-100"
        />
      </div>

      <Button
        type="submit"
        isDisabled={loading}
        isLoading={loading}
        label="Update"
        variant="primary"
        maxWidth={"full"}
      />
    </form>
  );
}
