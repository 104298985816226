export const enum RetrievalMode {
  Hybrid = "hybrid",
  Vectors = "vectors",
  Text = "text",
}

export const enum GPT4VInput {
  TextAndImages = "textAndImages",
  Images = "images",
  Texts = "texts",
}

export const enum VectorFieldOptions {
  Embedding = "embedding",
  ImageEmbedding = "imageEmbedding",
  Both = "both",
}

export type ChatAppRequestOverrides = {
  retrieval_mode?: RetrievalMode;
  semantic_ranker?: boolean;
  semantic_captions?: boolean;
  exclude_category?: string;
  top?: number;
  temperature?: number;
  minimum_search_score?: number;
  minimum_reranker_score?: number;
  prompt_template?: string;
  prompt_template_prefix?: string;
  prompt_template_suffix?: string;
  suggest_followup_questions?: boolean;
  use_oid_security_filter?: boolean;
  use_groups_security_filter?: boolean;
  use_gpt4v?: boolean;
  gpt4v_input?: GPT4VInput;
  vector_fields: VectorFieldOptions[];
  address_coordinates?: number[];
  intermediate_prompt?: string;
  finalized_prompt?: string;
};

export type SiteInfoRequest = {
  address: string;
  coordinates?: number[];
  report_hash?: string;
};

export type Region = {
  id: string,
  name: string,
  prompt: string,
}

export type Overlay = {
  id: string;
  name: string;
  type: string;
  description: string;
  fullname?: string;
  url: string;
};

export type Document = {
  id: string;
  created_at: string; // ISO 8601 format
  file_location?: string;
  name?: string;
  overlay?: string; // This references the 'overlays' table
};

export type Annotation = {
  id: string;
  created_at: string;
  filename: string;
  section?: string;
  boxes: any; // todo type this
};

export type Jurisdiction = {
  apn?: string;
  geometry: number[][];
  community_plan?: string;
  apc?: string;
  neighborhood_council?: string;
  addresses?: string[];
  lot_area?: number;
  council_district?: number;
  council_district_member: string;
};

export type SiteInfoResponse = {
  zoning: string;
  additional_zones: string[];
  general_plan?: string;
  general_plan_description?: string;
  zoning_description?: string;
  council_district: number; // Adjust the type accordingly
  latitude: number;
  longitude: number;
  historicPlace?: boolean;
  overlays?: Overlay[];
  ordinance?: Overlay[];
  jurisdiction?: Jurisdiction;
};

export type AddressLookupResponse = {
  answer: string;
  sources: string[];
  additional_sources: string[];
  conflict: boolean;
};

export type ResponseMessage = {
  content: string;
  role: string;
};

export type Thoughts = {
  title: string;
  description: any; // It can be any output from the api
  props?: { [key: string]: string };
};

export type ResponseContext = {
  data_points: string[];
  followup_questions: string[] | null;
  thoughts: Thoughts[];
};

export type ResponseChoice = {
  index: number;
  message: ResponseMessage;
  context: ResponseContext;
  session_state: any;
};

export type ChatAppResponseOrError = {
  choices?: ResponseChoice[];
  error?: string;
};

export type ChatAppResponse = {
  choices: ResponseChoice[];
};

export type ChatAppRequestContext = {
  overrides?: ChatAppRequestOverrides;
};

export type ChatAppRequest = {
  messages: ResponseMessage[];
  location?: string; // address (for now)
  context?: ChatAppRequestContext;
  stream?: boolean;
  session_state: any;
  user_query?: string; // Move this to a special RAG API
  additional_categories?: string[];
  change_zone?: string;
  user_filenames?: string[];
  report_hash?: string;
};

export type PermitTimelineRequest = {
  address: string;
  coordinates?: number[];
  permitSubType: string | undefined;
  change_zone?: string;
  report_hash?: string;
};

export type PermitTimelineResponse = {
  zone_filter: string;
  council_district: string;
  building_median: number;
  building_q1: number;
  building_q3: number;
  building_n: number;
  mechanical_median: number;
  mechanical_q1: number;
  mechanical_q3: number;
  mechanical_n: number;
  electrical_median: number;
  electrical_q1: number;
  electrical_q3: number;
  electrical_n: number;
  bar_data_building_business_unit: any;
  bar_data_building_permit_type: any;
  bar_data_mechanical_business_unit: any;
  bar_data_mechanical_permit_type: any;
  bar_data_electrical_business_unit: any;
  bar_data_electrical_permit_type: any;
  mechanical_permit_box_data: any; //todo put this type in a proto
  box_data_building_business_unit: any;
  box_data_building_permit_type: any;
  box_data_mechanical_business_unit: any;
  box_data_mechanical_permit_type: any;
  box_data_electrical_business_unit: any;
  box_data_electrical_permit_type: any;
};

export type MotionInfo = {
  id: string;
  title?: string;
  council_district: number;
  acting_body?: string;
  description?: string;
  url?: string;
  category?: string;
  last_updates?: string;
  status?: string;
  title_summary?: string;
};

export type GetMotionsRequest = {};

export type GetMotionsResponse = {
  motions: MotionInfo[];
};

export type Config = {
  showGPT4VOptions: boolean;
  showSemanticRankerOption: boolean;
  showVectorOption: boolean;
  showUserUpload: boolean;
  showSpeechInput: boolean;
  showSpeechOutput: boolean;
};

export type SimpleAPIResponse = {
  message?: string;
};

export type PermitData = {
  permit_nbr: string;
  duration_in_days: number;
  permit_type: string;
  permit_sub_type: string;
  business_unit: string;
};

export type PermitBarData = {
  bar: [week: number, amount: number];
};

export type AddressOptions = {
  city?: string;
  all_zones?: string[];
};

export type ChecklistQuestion = {
  query: string;
  format: string;
}

export type ChecklistSearchRequest = {
  questions: ChecklistQuestion[];
  lat?: number;
  lng?: number;
}

export interface LivenessData {
  [city: string]: {
    [service: string]: boolean;
  };
}
