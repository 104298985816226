import React from "react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { Dialog } from "@fluentui/react";
import { supabase } from "../../api/db";
import { userApi } from "../../app/globals/userApiSlice";
import Spinner from "../ui/Loaders/Spinner";

export default function AuthDialog() {
  const { data: session, isFetching } =
    userApi.endpoints.getAuthState.useQueryState();

  if (isFetching)
    return (
      <div className="fixed bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center bg-white">
        <Spinner />
      </div>
    );

  if (session || isFetching) return <></>;

  return (
    <Dialog hidden={false}>
      <Auth
        providers={["google"]}
        supabaseClient={supabase}
        appearance={{ theme: ThemeSupa }}
      />
    </Dialog>
  );
}
