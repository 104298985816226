import React, { useState } from "react";
import { useFetchUserAddressesQuery } from "../../../ReportGenerator/AddressSearchSection/addressSearchSectionApiSlice";

import { useAppSelector } from "../../../../app/hooks";
import AddressListItem from "./AddressListItem";
import Spinner from "../../../../components/ui/Loaders/Spinner";
import SearchAddress from "../../../../components/SearchAddress/SearchAddress";
import { useForm, SubmitHandler } from "react-hook-form";
import { useCreateAddressMutation } from "../../../ReportGenerator/AddressSearchSection/AddressSearchSectionMutationsApiSlice";
import { userApi } from "../../../../app/globals/userApiSlice";

type AddressSearchFormType = {
  address: string;
  coordinates: { lat: number; lng: number };
};

export default function SavedAddresses() {
  const { data: session } = userApi.endpoints.getAuthState.useQueryState();

  const [inputValue, setInputValue] = useState<string>("");

  const {
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<AddressSearchFormType>();

  const address = watch("address");

  const setAddress = (address: string) => {
    setValue("address", address);
  };

  const setCoords = (coords: { lat: number; lng: number }) => {
    setValue("coordinates", coords);
  };

  const userId = session ? session.user.id : "";

  const { data: savedAddressData, isLoading } = useFetchUserAddressesQuery(
    userId,
    {
      skip: userId === "",
    },
  );

  const [createAddress, { isLoading: createLoading }] =
    useCreateAddressMutation();

  const onSubmit: SubmitHandler<AddressSearchFormType> = async (data) => {
    if (!data.address || data.address.length === 0) return;

    const payload = {
      address: data.address,
      user_id: userId,
      lat: data.coordinates.lng,
      lng: data.coordinates.lat,
    };
    try {
      await createAddress(payload);
    } catch (error) {
      console.error("failed to saved address:", error);
    } finally {
      setInputValue("");
      reset();
    }
  };

  if (isLoading)
    return (
      <div className="py-12">
        <Spinner />
      </div>
    );

  return (
    <div className="flex flex-col gap-3">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="relative"
        id={"address-input"}
      >
        <SearchAddress
          address={address}
          setCoords={setCoords}
          setAddress={setAddress}
          loading={createLoading}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      </form>

      <div className="thin-scrollbar max-h-[320px] overflow-y-auto pt-6">
        <div className="flex flex-col gap-3 pr-2">
          {savedAddressData?.map((address) => (
            <AddressListItem address={address} key={address.id} />
          ))}
        </div>
      </div>
    </div>
  );
}
