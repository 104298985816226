import React, { useEffect, useState } from "react";
import { supabase } from "../../api";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { TelemetryData } from "./types/approach_log";
import { userApi } from "../../app/globals/userApiSlice";

interface TelemetryEntity {
  id: string;
  data: TelemetryData;
  created_at: string;
}

function ApproachLogsList() {
  const [telemetryData, setTelemetryData] = useState<TelemetryEntity[]>([]);
  const [expandedOverlay, setExpandedOverlay] = useState<number | null>(null);

  const { data: session } = userApi.endpoints.getAuthState.useQueryState();

  const handleToggleOverlay = (index: number) => {
    setExpandedOverlay(expandedOverlay === index ? null : index);
  };

  useEffect(() => {
    if (!session?.user?.id) {
      return;
    }
    const fetchTelemetryData = async () => {
      try {
        const { data, error } = await supabase
          .from("rag_approach_log")
          .select("*")
          .eq("user_id", session?.user.id)
          .order("created_at", { ascending: false })
          .limit(50);

        if (error) {
          throw error;
        }

        if (data) {
          setTelemetryData(data);
        }
      } catch (error) {
        console.error("Error fetching telemetry data:", error);
        // Handle the error appropriately, e.g., show an error message to the user
      }
    };

    fetchTelemetryData();
  }, [session]);
  return (
    <div>
      <h2 className={"text-xl font-semibold"}>Approach History</h2>
      <table>
        <thead>
          <tr>
            <th>User Query</th>
            <th>Address</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {telemetryData.map((item, index) => (
            <tr
              key={index}
              onClick={() =>
                (window.location.href = `/admin/telemetry/${item.id}`)
              }
              className="cursor-pointer transition-colors duration-150 ease-in-out hover:bg-gray-100"
            >
              <td className="max-w-2xl truncate border-b p-2">
                <div className="text-blue-600 hover:underline">
                  {item.data.userQuery}
                </div>
              </td>
              <td className="border-b p-2">{item.data.address ?? ""}</td>
              <td className="p-2">
                {new Date(item.created_at).toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ApproachLogsList;
