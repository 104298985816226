import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { userApi } from "../../app/globals/userApiSlice";
import { openModal } from "../../features/modalManager/modalSlice";

export default function useSubscriberGate() {
  const { data: session, isFetching: fetchingSession } =
    userApi.endpoints.getAuthState.useQueryState();
  const dispatch = useAppDispatch();

  const openMotionModal = () => {
    dispatch(
      openModal({
        type: "SubscriptionExpired",
      }),
    );
  };

  useEffect(() => {
    if (!session?.user || fetchingSession) {
      return;
    } else if (session?.user?.app_metadata["team"] == "redflag_team") {
      return;
    } else if (
      !session?.user?.app_metadata["subscriber_exp"] ||
      (session?.user?.app_metadata["subscriber_exp"] &&
        new Date(session.user.app_metadata["subscriber_exp"]) < new Date())
    ) {
      openMotionModal();
    }
  }, [session]);
}
