import { useEffect } from "react";
import { FooterActionsType } from "../components/FooterBtns";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../helpers/constants";
import { supabase } from "../../../api/db";
import { useAppDispatch } from "../../../app/hooks";
import { closeModal } from "../modalSlice";
import { CopyRegular } from "@fluentui/react-icons";
import Button from "../../../components/ui/Buttons/Button";
import { toast } from "react-toastify";

type Props = {
  setFooterActions: React.Dispatch<React.SetStateAction<FooterActionsType>>;
};

export default function SubscriptionExpired({ setFooterActions }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleCopyEmail = () => {
    navigator.clipboard.writeText("support@redflagdata.com");
    toast.success("Email copied to clipboard", { toastId: "emailCopied" });
  };

  useEffect(() => {
    setFooterActions({
      secondaryBtn: {
        label: "Log out",
        action: () => {
          supabase.auth.signOut().then(() => navigate(ROUTES.home));
          dispatch(closeModal());
        },
      },
    });
  }, []);

  return (
    <div className="flex h-full flex-col justify-center gap-4 text-center">
      <p className="body-font">
        Your subscription has expired. Please contact our support team to
        restore access.
      </p>

      <Button
        label={"support@redflagdata.com"}
        icon={{ icon: <CopyRegular />, position: "right" }}
        variant="outline"
        maxWidth={"full"}
        onClick={handleCopyEmail}
      />
    </div>
  );
}
