import React, { useState, useEffect } from "react";
import { supabase } from "../../api";
import AnswerToUserQuestion from "../../features/ReportGenerator/AnswerToUserQuestion/AnswerToUserQuestion";
import { EndpointOutputs } from "../../global/types";
import { JsonViewer } from "./JsonViewer";
import ReportSection from "../ui/ReportSection/ReportSection";
import { useParams } from "react-router-dom";
import { AnswerElements } from "../../features/ReportGenerator/AnswerToUserQuestion/util/AnswerToElements";

interface StoredAnswer {
  id: number;
  answer: any;
  title: string;
  query: string;
}

interface AddressSearchState {
  isLoading: boolean;
  endpointOutputs: EndpointOutputs;
}

interface StoredReportViewProps {
  propAnswers?: StoredAnswer[];
}

function StoredReportView({ propAnswers }: StoredReportViewProps) {
  const [answers, setAnswers] = useState<StoredAnswer[]>([]);
  const { id } = useParams<{ id: string }>();
  const [address, setAddress] = useState("");
  const [queries, setQueries] = useState<string[]>([]);

  useEffect(() => {
    if (propAnswers && propAnswers.length) {
      setAnswers(propAnswers);
    } else {
      fetchAnswers();
    }
  }, [propAnswers, id]);

  const fetchAnswers = async () => {
    try {
      const { data, error } = await supabase
        .from("answers")
        .select(
          `*,
            reports(*, stored_addresses(*)),
            stored_searches(*)
            `,
        )
        .eq("report", id);
      if (error) throw error;

      if (data) {
        const formattedAnswers: StoredAnswer[] = data.map((item: any) => ({
          id: item.id,
          answer: item.answer,
          title: item.title,
          query: item.query,
        }));
        setAnswers(formattedAnswers);
        setAddress(data[0]?.reports?.stored_addresses?.address ?? undefined);
        setQueries(
          data.map((item: any) => item?.stored_searches?.query ?? undefined),
        );
      }
    } catch (error) {
      console.error("Error fetching answers:", error);
    }
  };
  const toMessage = (answer: string | AnswerElements): AddressSearchState => {
    return {
      endpointOutputs: {
        selectedAnswer: 0,
        parkingAnswer: [],
        signageAnswer: [],
        setbackAnswer: [],
        streamedAnswers: [],
        userQueryAnswer: [
          [
            "",
            {
              choices: [
                {
                  message: {
                    content:
                      typeof answer === "string"
                        ? answer
                        : JSON.stringify(answer, null, 2),
                    role: "assistant",
                  },
                  index: 0,
                  context: {
                    data_points: [],
                    followup_questions: null,
                    thoughts: [],
                  },
                  session_state: undefined,
                },
              ],
            },
          ],
        ],
        permitTimeline: undefined,
        siteInfoResponse: undefined,
        motionsResponse: undefined,
      },
      isLoading: false,
    };
  };

  return (
    <>
      {!propAnswers && address && (
        <p className="header-font py-2 text-center text-[28px]">{address}</p>
      )}
      {answers.map((answer, i) => (
        <div key={i}>
          <AnswerToUserQuestion
            completeAnswer={toMessage(answer.answer)}
            title={answer.title ?? ""}
            query={answer.query ?? ""}
          />
          <ReportSection
            collapsible
            title={"Raw Data (JSON)"}
            isInitiallyOpen={false}
          >
            <JsonViewer answers={[answer]} />
          </ReportSection>
        </div>
      ))}
    </>
  );
}

export default StoredReportView;
