import {supabase, Overlay} from "../../../api";

export class OverlayCrud {

    async createOverlay(overlay: Omit<Overlay, 'id' | 'created_at'>): Promise<any> {
        const {data, error} = await supabase
            .from('overlays')
            .insert([overlay]);
        if (error) throw error;
        return data;
    }

    async getOverlays(): Promise<Overlay[]> {
        const {data, error} = await supabase
            .from('overlays')
            .select('*')
            .order('id');
        if (error) throw error;
        return data;
    }
    async getOverlaysStartsWith(startsWith: string): Promise<Overlay[]> {
        const {data, error} = await supabase
            .from('overlays')
            .select('*')
            .ilike('name', `${startsWith}%`)
            .order('id');
        if (error) throw error;
        return data;
    }
    async updateOverlay(id: string, overlay: Partial<Overlay>): Promise<any> {
        const {data, error} = await supabase
            .from('overlays')
            .update(overlay)
            .eq('id', id);
        if (error) throw error;
        return data;
    }

    async deleteOverlay(id: string): Promise<any> {
        const {data, error} = await supabase
            .from('overlays')
            .delete()
            .eq('id', id);
        if (error) throw error;
        return data;
    }
}
