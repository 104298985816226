import React from "react";
import DropdownMenu from "../../ui/DropdownMenu/DropdownMenu";
import { Person24Filled } from "@fluentui/react-icons";
import Button from "../../ui/Buttons/Button";
import userDropdownOptions from "../userDropdownOptions";

export default function ProfileDropdown() {
  const { options } = userDropdownOptions();

  const trigger = (
    <Button
      variant="ghost"
      ariaLabel="User account menu"
      icon={{
        icon: <Person24Filled />,
        rounded: true,
        className: "text-white",
      }}
    />
  );

  return <DropdownMenu placement="bottom-left" maxWidth={220}  trigger={trigger} options={options} />;
}
