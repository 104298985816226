import { useEffect, useRef } from "react";

/**
 * A custom hook that handles closing a dropdown menu on scroll
 * @param isOpen - Boolean indicating if the dropdown is open
 * @param trigger - Function to call when the dropdown should close
 * @param threshold - Optional minimum scroll distance before closing (default: 50px)
 */

type Props = { isOpen: boolean; trigger: () => void; threshold?: number };

const useTriggerOnScroll = ({ isOpen, trigger, threshold = 50 }: Props) => {
  // Track initial scroll position when scrolling starts
  const initialScrollPosition = useRef(0);
  // Track whether we're currently in an active scroll
  const isScrolling = useRef(false);
  // Debounce timer for scroll end detection
  const scrollTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!isOpen) return;

    const handleScroll = () => {
      // If we're not already scrolling, this is the start of a new scroll
      if (!isScrolling.current) {
        initialScrollPosition.current = window.scrollY;
        isScrolling.current = true;
      }

      // Calculate total distance from where scroll started
      const totalDistance = Math.abs(
        window.scrollY - initialScrollPosition.current,
      );

      // Clear any existing timer
      if (scrollTimer.current) {
        clearTimeout(scrollTimer.current);
      }

      // Set a timer to detect when scrolling stops
      scrollTimer.current = setTimeout(() => {
        isScrolling.current = false;
      }, 150); // Wait 150ms of no scroll events before considering scroll finished

      // Only trigger if we've moved far enough from initial position
      if (totalDistance > threshold) {
        trigger();
        isScrolling.current = false;
        if (scrollTimer.current) {
          clearTimeout(scrollTimer.current);
        }
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (scrollTimer.current) {
        clearTimeout(scrollTimer.current);
      }
    };
  }, [isOpen, trigger, threshold]);
};

export default useTriggerOnScroll;
