import React from "react";
import { Map } from "@vis.gl/react-google-maps";
import { Polygon } from "../../../../components/ParcelMap";

interface Props {
  className?: string;
  geometry?: number[][];
}

const ParcelOnMap = ({ className, geometry }: Props) => {
  // Define the coordinates for the polygon
  if (!geometry) {
    return <div></div>;
  }

  const coords = geometry.map((arr) => {
    return { lng: arr[0], lat: arr[1] };
  });

  const bounds = new google.maps.LatLngBounds();
  coords.forEach((c) => bounds.extend(new google.maps.LatLng(c.lat, c.lng)));
  let north = bounds.getNorthEast().lat();
  let east = bounds.getNorthEast().lng();
  let south = bounds.getSouthWest().lat();
  let west = bounds.getSouthWest().lng();

  // Very basic calculation that will approximate the center
  let approxCenter = coords.reduce(
    (accumulator, currentValue) => {
      return {
        lat: accumulator.lat + currentValue.lat / coords.length,
        lng: accumulator.lng + currentValue.lng / coords.length,
      };
    },
    { lat: 0, lng: 0 },
  );
  return (
    <Map
      defaultBounds={{ north: north, south: south, east: east, west: west }}
      className={className}
      // Disable map type control (satellite view)
      mapTypeControl={false}
      // Disable street view control
      streetViewControl={false}
    >
      {!!coords && (
        <Polygon
          polygonCoordinates={[
            coords.map((c) => new google.maps.LatLng(c.lat, c.lng)),
          ]}
          strokeColor={"#cc5500"} // Dark orange
          strokeOpacity={1}
          strokeWeight={4}
          fillColor={"#ffa500"} // Orange
          fillOpacity={0.3}
        />
      )}
    </Map>
  );
};

export default ParcelOnMap;
