import { useState } from "react";
import Accordion from "../../../../components/ui/Accordion/Accordion";
import { SavedSearchesInfo } from "../../helpers/demoSectionInfo";
import AccordionSectionSpacer from "../../../../components/ui/Accordion/AccordionSectionSpacer";
import { useAppSelector } from "../../../../app/hooks";
import SavedSearchesSkeleton from "./SavedSearchesSkeleton";
import { dataArray } from "./data/data";
import SavedSection from "./SavedSection";
import { answerToElements } from "../../../ReportGenerator/AnswerToUserQuestion/util/AnswerToElements";
import Radio from "../../../../components/forms/Components/Radio";
import RadioContainer from "../../../../components/forms/Components/RadioContainer";

export default function SavedSearches() {
  const { isLoading } = useAppSelector((state) => state.demo);
  const [selectedFilter, setSelectedFilter] = useState<string>("all");

  const filterOptions = [
    { label: "Searches performed on All Data", value: "all" },
    { label: "Searches performed on Public Data only", value: "public" },
    { label: "Searches performed on My Secure Data only", value: "myData" },
  ];

  const filteredDataArray = dataArray
    .map((item) => {
      const { parsedAnswerElements, sources, additionalSources } =
        answerToElements({
          isLoading: isLoading,
          message: item.answer,
          navigateToCitation: () => {},
        });
      return item;
    })
    .filter((item) => {
      if (selectedFilter === "all") return true; // Show all data
      if (selectedFilter === "myData") return item.userData === true; // Show userData: true
      if (selectedFilter === "public") return item.userData === false; // Show userData: false
      return true; // Fallback to show all data
    });

  const hasOpenItem = filteredDataArray.some((item) => item.isInitiallyOpen);

  const updatedDataArray =
    filteredDataArray.length > 0 && !hasOpenItem
      ? filteredDataArray.map((item, i) =>
          i + 1 === filteredDataArray.length
            ? { ...item, isInitiallyOpen: true }
            : item,
        )
      : filteredDataArray;

  if (isLoading) return <SavedSearchesSkeleton />;

  return (
    <Accordion
      childPadding={false}
      title={SavedSearchesInfo.title}
      id={SavedSearchesInfo.id}
      key={selectedFilter}
    >
      {/* Filter Section */}
      <div className="flex justify-between gap-4 p-5 shadow-[0px_0px_20px_0px_#00000040] sm:justify-start sm:gap-40">
        <h2 className="subheader-font font-bold">Filter results</h2>
        <RadioContainer ariaLabel="">
          {filterOptions.map(({ label, value }) => (
            <div key={value} className="flex items-center">
              <Radio
                key={value}
                name="filter"
                value={value}
                label={label}
                checked={selectedFilter === value}
                onChange={(e) => setSelectedFilter(e.target.value)}
                labelClass="desc-font"
              />
            </div>
          ))}
        </RadioContainer>
      </div>

      {/* Saved Searches */}
      <div className="p-1.5 sm:p-6">
        <AccordionSectionSpacer>
          {updatedDataArray.map((sectionData, i) => (
            <SavedSection key={i} data={sectionData} />
          ))}
        </AccordionSectionSpacer>
      </div>
    </Accordion>
  );
}
