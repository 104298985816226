import React from "react";
import { DropdownOption } from "../../../components/ui/DropdownMenu/OptionsRenderer";
import OptionsRenderer from "../../../components/ui/DropdownMenu/OptionsRenderer";
import { closeModal } from "../modalSlice";
import { useAppDispatch } from "../../../app/hooks";

export type DynamicDropdownOptionsListProps = {
  options: DropdownOption[];
  trigger?: JSX.Element;
};

export default function DynamicDropdownOptionsList({
  options,
}: DynamicDropdownOptionsListProps) {
  const dispatch = useAppDispatch();

  const handleOptionClick = (onClick: () => void) => {
    dispatch(closeModal());
    onClick();
  };

  return (
    <div>
      <OptionsRenderer
        onOptionSelect={handleOptionClick}
        options={options}
        size="large"
      />
    </div>
  );
}
