import React from "react";
import { DropdownOption } from "../ui/DropdownMenu/OptionsRenderer";
import { useNavigate } from "react-router-dom";
import { userApi } from "../../app/globals/userApiSlice";
import { ROUTES } from "../../helpers/constants";
import { supabase } from "../../api/db";

export default function userDropdownOptions(): { options: DropdownOption[] } {
  const navigate = useNavigate();

  const { data: session } = userApi.endpoints.getAuthState.useQueryState();
  const userId = session?.user?.id;
  const { data: userData } = userApi.endpoints.getUserById.useQueryState(
    userId ?? "",
  );
  const firstName = userData?.first_name;

  const options: DropdownOption[] = [
    {
      type: "header",
      label: `Hello, ${firstName ?? session?.user?.email ?? ""}`,
      hidden: !firstName && !session?.user?.email,
    },

    {
      type: "option",
      label: "Profile Settings",
      onClick: () => navigate(ROUTES.account),
    },
    {
      type: "divider",
    },
    {
      type: "option",
      label: "Sign Out",
      onClick: () => supabase.auth.signOut().then(() => navigate(ROUTES.home)),
    },
  ];

  return { options };
}
