import React, { useEffect, useRef, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { closeModal } from "../modalSlice";
import { twMerge } from "tailwind-merge";
import { useFocusTrap } from "../../../hooks/useFocusTrap";
import FooterBtns from "./FooterBtns";
import { FooterActionsType } from "./FooterBtns";

const SNAP_POINTS = {
  auto: "auto",
  xs: "h-[25vh]",
  sm: "h-[35vh]",
  md: "h-[50vh]",
  lg: "h-[70vh]",
  xl: "h-[80vh]",
  full: "h-[90vh]",
};

interface BottomSheetProps {
  SpecificModal: React.ComponentType<{
    setFooterActions?: React.Dispatch<React.SetStateAction<FooterActionsType>>;
  }>;
}

export default function BottomSheet({ SpecificModal }: BottomSheetProps) {
  const [footerActions, setFooterActions] = useState<FooterActionsType>({});

  const dispatch = useAppDispatch();
  const { isOpen, currentModal, title, mobileSize, canClose } = useAppSelector(
    (state) => state.modal,
  );

  const isDisabled =
    footerActions.primaryBtn?.isLoading ||
    footerActions.secondaryBtn?.isLoading ||
    !canClose;

  const handleCloseModal = () => {
    if (isDisabled) return;

    dispatch(closeModal());
  };

  const sheetRef = useFocusTrap({
    isOpen,
    onEscape: handleCloseModal,
  });

  const dragRef = useRef<HTMLDivElement>(null);
  const startY = useRef(0);
  const currentY = useRef(0);

  const [isDragging, setIsDragging] = useState(false);
  const [animation, setAnimation] = useState<Animation | null>(null);

  // Handle initial mounting animation
  useEffect(() => {
    if (isOpen && sheetRef.current) {
      const sheet = sheetRef.current;
      const animation = sheet.animate(
        [{ transform: "translateY(100%)" }, { transform: "translateY(0)" }],
        {
          duration: 300,
          easing: "cubic-bezier(0.4, 0, 0.2, 1)",
          fill: "forwards",
        },
      );
      setAnimation(animation);
    }
  }, [isOpen]);
  // Gesture Handlers
  const handleDragStart = (e: React.TouchEvent | React.MouseEvent) => {
    e.preventDefault(); // Prevent any default handling

    setIsDragging(true);
    startY.current = "touches" in e ? e.touches[0].clientY : e.clientY;
    currentY.current = startY.current;

    // Get current transform value before canceling animation
    if (sheetRef.current) {
      const transform = getComputedStyle(sheetRef.current).transform;
      if (transform !== "none") {
        const matrix = new DOMMatrix(transform);
        startY.current += matrix.m42; // Add current transform to starting position
        //  initialDragY.current.start += matrix.m42;
      }
    }

    if (animation) {
      animation.cancel();
    }
  };

  const handleDragMove = (e: React.TouchEvent | React.MouseEvent) => {
    if (!isDragging) return;
    e.preventDefault(); // Prevent scrolling while dragging

    const clientY = "touches" in e ? e.touches[0].clientY : e.clientY;
    const delta = clientY - startY.current;

    if (delta < 0) return; // Prevent dragging upward past initial position

    if (sheetRef.current) {
      requestAnimationFrame(() => {
        if (sheetRef.current) {
          sheetRef.current.style.transform = `translateY(${delta}px)`;
        }
      });
    }
    currentY.current = clientY;
  };

  const handleDragEnd = () => {
    if (!isDragging) return;
    setIsDragging(false);

    const delta = currentY.current - startY.current;
    const threshold = window.innerHeight * 0.2; // threshold for dismiss

    if (delta > threshold) {
      handleCloseModal();
    } else {
      // Snap back to initial position
      const sheet = sheetRef.current;
      if (sheet) {
        const animation = sheet.animate(
          [
            { transform: `translateY(${delta}px)` },
            { transform: "translateY(0)" },
          ],
          {
            duration: 150,
            easing: "cubic-bezier(0.4, 0, 0.2, 1)",
            fill: "forwards",
          },
        );

        animation.onfinish = () => {
          sheet.style.transform = "";
        };

        setAnimation(animation);
      }
    }
  };

  if (!isOpen || !currentModal) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      role="dialog"
      aria-modal="true"
      aria-labelledby="bottom-sheet-title"
    >
      <div
        ref={sheetRef}
        onMouseMove={isDragging ? handleDragMove : undefined}
        onMouseUp={isDragging ? handleDragEnd : undefined}
        onTouchMove={isDragging ? handleDragMove : undefined}
        onTouchEnd={isDragging ? handleDragEnd : undefined}
        className={twMerge(
          "fixed bottom-0 left-0 right-0 flex w-full touch-none flex-col rounded-t-xl bg-white shadow-lg will-change-transform",
          mobileSize === "auto" ? "max-h-[90vh]" : SNAP_POINTS[mobileSize],
        )}
      >
        {/* Drag Handle */}
        <div
          ref={dragRef}
          className={twMerge(
            "group touch-none",
            isDisabled
              ? "cursor-not-allowed"
              : "cursor-grab active:cursor-grabbing",
          )}
          onMouseDown={!isDisabled ? handleDragStart : undefined}
          onTouchStart={!isDisabled ? handleDragStart : undefined}
        >
          <div className="mx-auto my-2 h-1.5 w-12 rounded-full bg-stone-300 transition-colors group-active:bg-stone-400" />
        </div>

        {/* Header */}
        <div className="flex justify-between border-b border-stone-200 px-4 pb-4">
          <h2
            id="bottom-sheet-title"
            className="subheader-font line-clamp-1 grow select-none break-all font-bold"
          >
            {title}
          </h2>
        </div>

        {/* Content */}
        <div className="thin-scrollbar pb-safe flex grow flex-col overflow-y-auto overscroll-contain p-4">
          <SpecificModal
            setFooterActions={setFooterActions}
            {...currentModal.props}
          />
        </div>

        <FooterBtns
          secondaryBtn={footerActions.secondaryBtn}
          primaryBtn={footerActions.primaryBtn}
        />
      </div>
    </div>
  );
}
