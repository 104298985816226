import {supabase, Region} from "../../../api";

export class RegionCrud {

  async createRegion(region: Omit<Region, 'id' | 'created_at'>): Promise<any> {
    const {data, error} = await supabase
      .from('regions')
      .insert([region]);
    if (error) throw error;
    return data;
  }

  async getRegions(): Promise<Region[]> {
    const {data, error} = await supabase
      .from('regions')
      .select('*')
      .order('id');
    if (error) throw error;
    return data;
  }

  async updateRegion(id: string, region: Partial<Region>): Promise<any> {
    const {data, error} = await supabase
      .from('regions')
      .update(region)
      .eq('id', id);
    if (error) throw error;
    return data;
  }

  async deleteRegion(id: string): Promise<any> {
    const {data, error} = await supabase
      .from('regions')
      .delete()
      .eq('id', id);
    if (error) throw error;
    return data;
  }
}
