import React, { useState, useRef, useEffect } from "react";
import useClickOutside from "../../../hooks/useClickOutside";
import { twMerge } from "tailwind-merge";
import { PlacementType } from "../../../global/types";
import { BreakpointKey, useIsBelow } from "../../../app/globals/screenSlice";
import { useOptimalPlacement } from "../../../hooks/useOptimalPlacement ";
import { createPortal } from "react-dom";

type Props = {
  children: React.ReactNode;
  content: string | string[] | React.ReactNode;
  className?: string;
  placement?: PlacementType;
  trigger?: "hover" | "click";
  maxWidth?: number;
  hideBelow?: BreakpointKey;
  containerWidth?: "full" | "fit";
};

const arrowClasses: Record<PlacementType, string> = {
  "top-center":
    "bottom-0 left-1/2 -translate-x-1/2 translate-y-full border-t-stone-900 border-x-transparent border-b-transparent",
  "right-center":
    "left-0 top-1/2 -translate-x-full -translate-y-1/2 border-r-stone-900 border-y-transparent border-l-transparent",
  "bottom-center":
    "top-0 left-1/2 -translate-x-1/2 -translate-y-full border-b-stone-900 border-x-transparent border-t-transparent",
  "left-center":
    "right-0 top-1/2 translate-x-full -translate-y-1/2 border-l-stone-900 border-y-transparent border-r-transparent",
  "top-left":
    "bottom-0 right-4 translate-y-full border-t-stone-900 border-x-transparent border-b-transparent",
  "top-right":
    "bottom-0 left-4 translate-y-full border-t-stone-900 border-x-transparent border-b-transparent",
  "bottom-left":
    "top-0 right-4 -translate-y-full border-b-stone-900 border-x-transparent border-t-transparent",
  "bottom-right":
    "top-0 left-4 -translate-y-full border-b-stone-900 border-x-transparent border-t-transparent",
};

const Tooltip = ({
  children,
  content,
  className = "",
  placement = "top-center",
  trigger = "hover",
  maxWidth = 360,
  hideBelow = "sm",
  containerWidth = "fit",
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const isBelowBreakpoint = useIsBelow(hideBelow);

  const { actualPlacement, style } = useOptimalPlacement({
    containerRef: containerRef,
    floatingRef: tooltipRef,
    initialPlacement: placement,
    isVisible: isVisible,
  });

  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);

  const handleClickOutside = () => {
    if (trigger === "click") {
      hide();
    }
  };

  useClickOutside({ ref: containerRef, onClickOutside: handleClickOutside });

  const handleMouseEnter = () => {
    if (trigger === "hover") {
      show();
    }
  };

  const handleMouseLeave = () => {
    if (trigger === "hover") {
      hide();
    }
  };

  const renderContent = () => {
    if (typeof content === "string") {
      return <p className="desc-font break-words text-white">{content}</p>;
    }

    if (Array.isArray(content)) {
      return (
        <div className="space-y-1">
          {content.map((text, index) => (
            <p key={index} className="desc-font text-white">
              {text}
            </p>
          ))}
        </div>
      );
    }

    return content;
  };

  return (
    <div
      ref={containerRef}
      className={twMerge(containerWidth === "fit" ? "w-fit" : "w-full")}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={trigger === "click" ? (isVisible ? hide : show) : undefined}
    >
      {children}
      {isVisible &&
        !isBelowBreakpoint &&
        createPortal(
          <div
            ref={tooltipRef}
            style={{ maxWidth: maxWidth, ...style }}
            className={twMerge("rounded-lg bg-stone-900 px-3 py-2", className)}
            onMouseEnter={trigger === "click" ? undefined : hide}
          >
            {renderContent()}
            <div
              className={`absolute h-0 w-0 border-[6px] ${arrowClasses[actualPlacement]}`}
              aria-hidden="true"
            />
          </div>,
          document.body,
        )}
    </div>
  );
};

export default Tooltip;
