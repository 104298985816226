import { Link } from "react-router-dom";
import ProfileDropdown from "./ProfileDropdown";
import { userApi } from "../../../app/globals/userApiSlice";

export default function DesktopNav() {
  const { data: session } = userApi.endpoints.getAuthState.useQueryState();

  return (
    <header
      className={
        "z-10 flex h-[80px] flex-row items-center justify-between bg-rf-dark-brown px-9 pr-7 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]"
      }
      role={"banner"}
    >
      <Link
        to="/"
        className={`header-font text-nowrap font-bold text-white`}
        aria-label="Home"
      >
        redflag
      </Link>

      {session ? <ProfileDropdown /> : <div />}
    </header>
  );
}
