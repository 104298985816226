import React, { useMemo } from "react";
import ReportSection from "../../../../../components/ui/ReportSection/ReportSection";
import ZoningUseClassificationSkeleton from "./ZoningUseClassificationSkeleton";
import useSearchMutationState from "../../../AddressSearchSection/hooks/useSearchMutationState";
import AccordionSectionSpacer from "../../../../../components/ui/Accordion/AccordionSectionSpacer";
import ZoningSection from "./ZoningSection";

export default function ZoningUseClassification() {
  const {
    mutations: {
      zoning: { data, isLoading },
    },
  } = useSearchMutationState();

  const filteredData = useMemo(() => {
    if (!data) return [];
    return data.filter(
      (item) => item.answer && Object.keys(item.answer).length > 0,
    );
  }, [data]);

  if (isLoading) return <ZoningUseClassificationSkeleton />;
  if (!data || filteredData.length === 0) return <></>;

  return (
    <ReportSection
      childPadding={false}
      title="Zoning Use Classification"
      collapsible
      limitMaxHeight={false}
    >
      <div className="ml-1.5 mt-1.5 sm:ml-5 sm:mt-2.5">
        <AccordionSectionSpacer>
          {filteredData.map((item, index) => (
            <ZoningSection key={index} response={item} />
          ))}
        </AccordionSectionSpacer>
      </div>
    </ReportSection>
  );
}
