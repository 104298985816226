import React from "react";
import { twMerge } from "tailwind-merge";

export type DropdownOption =
  | {
      type: "option";
      label: string;
      onClick: () => void;
      disabled?: boolean;
      hidden?: boolean;
      icon?: JSX.Element;
    }
  | {
      type: "header";
      label: string;
      hidden?: boolean;
    }
  | {
      type: "divider";
      hidden?: boolean;
    };

type Props = {
  options: DropdownOption[];
  onOptionSelect: (handler: () => void) => void; //mainly used to close whatever we opened
  darkBg?: boolean;
  size?: "small" | "medium" | "large";
};

export default function OptionsRenderer({
  options,
  onOptionSelect,
  darkBg,
  size = "medium",
}: Props) {
  return (
    <>
      {options.map((option, index) => {
        switch (option.type) {
          case "option":
            return option.hidden ? (
              <></>
            ) : (
              <button
                disabled={option.disabled}
                key={index}
                onClick={() => onOptionSelect(option.onClick)}
                className={twMerge(
                  "flex w-full items-center gap-2 rounded-md px-3 duration-300",
                  "disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent disabled:active:bg-transparent",
                  "hover:bg-rf-very-light-gray active:bg-rf-light-gray",
                  darkBg && "text-white hover:bg-stone-700 active:bg-stone-600",
                  size === "small" && "desc-font py-1.5",
                  size === "medium" && "body-font py-2.5",
                  size === "large" && "body-font py-3.5",
                )}
                role="menuitem"
              >
                {option.icon && option.icon}
                <p className="line-clamp-1 text-nowrap text-left">
                  {option.label}
                </p>
              </button>
            );
          case "header":
            return option.hidden ? (
              <></>
            ) : (
              <div
                key={index}
                className={twMerge(
                  "px-3 pr-10 font-[500]",
                  darkBg && "text-white",
                  size === "small" && "desc-font py-1.5",
                  size === "medium" && "body-font py-2",
                  size === "large" && "body-font py-2.5",
                )}
                role="presentation"
              >
                <p className="line-clamp-1 text-nowrap text-left">
                  {option.label}
                </p>
              </div>
            );
          case "divider":
            return option.hidden ? (
              <></>
            ) : (
              <hr key={index} className="line-break mx-3 my-1" />
            );
          default:
            return null;
        }
      })}
    </>
  );
}
