import { useRef } from "react";
import { twMerge } from "tailwind-merge";
import { PanelLeftText24Regular } from "@fluentui/react-icons";
import { createPortal } from "react-dom";
import Button from "../Buttons/Button";
import useClickOutside from "../../../hooks/useClickOutside";
import { Dismiss16Filled } from "@fluentui/react-icons";

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  toggleDrawer: () => void; // dont forget to add toggleVerticalBodyScroll to the function
  side?: "left" | "right";
  trigger?: React.ReactNode;
  title?: string;
  width?: number | string;
  hasBackdrop?: boolean;
  closeOnClickOutside?: boolean;
};

export default function OverlayDrawer({
  children,
  side = "left",
  trigger,
  isOpen,
  toggleDrawer,
  width = "100%",
  title,
  hasBackdrop = false,
  closeOnClickOutside,
}: Props) {
  const drawerRef = useRef<HTMLDivElement>(null);

  const defaultToggleBtn = (
    <Button
      onClick={toggleDrawer}
      ariaLabel={isOpen ? "Close navigation menu" : "Open navigation menu"}
      variant="ghost"
      icon={{
        icon: !isOpen ? <PanelLeftText24Regular /> : <Dismiss16Filled />,

        rounded: true,
        className: isOpen ? "text-white" : "",
      }}
    />
  );

  const handleCLickOutside = () => {
    if (isOpen && closeOnClickOutside) toggleDrawer();
  };

  useClickOutside({ ref: drawerRef, onClickOutside: handleCLickOutside });

  const triggerElement = trigger || defaultToggleBtn;

  return (
    <>
      {triggerElement}

      {createPortal(
        <>
          {/* Backdrop with blur effect */}
          {hasBackdrop && (
            <div
              className={twMerge(
                "fixed inset-0 z-30 bg-stone-900/50 backdrop-blur-sm transition-opacity duration-300",
                isOpen ? "opacity-100" : "pointer-events-none opacity-0",
              )}
              aria-hidden="true"
            />
          )}

          {/* drawer */}
          <div
            ref={drawerRef}
            style={{
              minWidth: 240,
              width: width,
              maxWidth: "100vw",
            }}
            className={twMerge(
              `fixed bottom-0 top-0 z-40 h-[calc(100vh)] bg-rf-very-dark-gray transition-transform duration-300 ease-in-out`,
              side === "right" ? "right-0" : "left-0",
              isOpen
                ? "translate-x-0"
                : side === "right"
                  ? "translate-x-full"
                  : "-translate-x-full",
            )}
          >
            <div
              className={twMerge(
                "flex items-center justify-between gap-2 p-3 pl-5",
                side === "left" && "flex-row-reverse",
              )}
            >
              <p className="subheader-font line-clamp-1 break-all font-medium text-white">
                {title}
              </p>
              {/* Close Button */}
              <div
                className={twMerge(
                  "flex justify-end",
                  side === "right" ? "justify-start" : "justify-end",
                )}
              >
                {defaultToggleBtn}
              </div>
            </div>

            {/* Drawer Content */}
            <div>{children}</div>
          </div>
        </>,

        document.body,
      )}
    </>
  );
}
