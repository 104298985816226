import { useState, useEffect, RefObject } from "react";
import { PlacementType } from "../global/types";
import { CSSProperties } from "react";

type Props = {
  containerRef: RefObject<HTMLElement>;
  floatingRef: RefObject<HTMLElement>;
  initialPlacement: PlacementType;
  isVisible: boolean;
};

interface Position {
  top: number;
  left: number;
}

/**
 * A custom hook that handles optimal positioning of floating elements (like dropdowns, tooltips, popovers)
 * relative to their trigger elements. This hook provides both the optimal placement direction and exact
 * pixel coordinates for positioning the floating element.
 *
 * The hook manages two aspects of positioning:
 * 1. Placement Direction: Determines which side of the trigger element is best (top, bottom, left, right)
 * 2. Exact Coordinates: Calculates precise pixel positions for the floating element
 *
 * It handles:
 * - Viewport overflow prevention
 * - Dynamic repositioning on scroll/resize
 * - Proper positioning for portaled elements
 * - Consistent alignment and spacing
 */

export const useOptimalPlacement = ({
  containerRef,
  floatingRef,
  initialPlacement,
  isVisible,
}: Props) => {
  const [actualPlacement, setActualPlacement] =
    useState<PlacementType>(initialPlacement);
  const [position, setPosition] = useState<Position | null>(null);

  const calculateOptimalPlacement = () => {
    if (!floatingRef.current || !containerRef.current) return initialPlacement;

    const floatingRect = floatingRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;

    // Calculate available space in each direction
    const spaceAbove = containerRect.top;
    const spaceBelow = viewportHeight - containerRect.bottom;
    const spaceLeft = containerRect.left;
    const spaceRight = viewportWidth - containerRect.right;

    // We'll use this to determine if a centered position would fit
    const getCenterPosition = () => {
      const centerPosLeft =
        containerRect.left + (containerRect.width - floatingRect.width) / 2;
      return {
        wouldFitHorizontally:
          centerPosLeft >= 0 &&
          centerPosLeft + floatingRect.width <= viewportWidth,
        wouldFitVertically:
          containerRect.top +
            (containerRect.height - floatingRect.height) / 2 >=
            0 &&
          containerRect.top +
            (containerRect.height + floatingRect.height) / 2 <=
            viewportHeight,
      };
    };

    // First, try to honor the initial placement if there's enough space
    const gap = 8;
    const { wouldFitHorizontally, wouldFitVertically } = getCenterPosition();

    // Check if the initial placement would work
    switch (initialPlacement) {
      case "top-center":
        if (spaceAbove >= floatingRect.height + gap && wouldFitHorizontally) {
          return "top-center";
        }
        break;

      case "bottom-center":
        if (spaceBelow >= floatingRect.height + gap && wouldFitHorizontally) {
          return "bottom-center";
        }
        break;

      case "left-center":
        if (spaceLeft >= floatingRect.width + gap && wouldFitVertically) {
          return "left-center";
        }
        break;

      case "right-center":
        if (spaceRight >= floatingRect.width + gap && wouldFitVertically) {
          return "right-center";
        }
        break;

      case "top-left":
        if (
          spaceAbove >= floatingRect.height + gap &&
          containerRect.right - floatingRect.width >= 0
        ) {
          return "top-left";
        }
        break;

      case "top-right":
        if (
          spaceAbove >= floatingRect.height + gap &&
          containerRect.left + floatingRect.width <= viewportWidth
        ) {
          return "top-right";
        }
        break;

      case "bottom-left":
        if (
          spaceBelow >= floatingRect.height + gap &&
          containerRect.right - floatingRect.width >= 0
        ) {
          return "bottom-left";
        }
        break;

      case "bottom-right":
        if (
          spaceBelow >= floatingRect.height + gap &&
          containerRect.left + floatingRect.width <= viewportWidth
        ) {
          return "bottom-right";
        }
        break;
    }

    // If we couldn't honor the initial placement, fall back to our optimal placement logic
    const isNearRight = spaceRight < floatingRect.width + gap;
    const isNearLeft = spaceLeft < floatingRect.width + gap;
    const isNearTop = spaceAbove < floatingRect.height + gap;
    const isNearBottom = spaceBelow < floatingRect.height + gap;

    // If both sides are constrained, prioritize vertical center placement
    if (isNearLeft && isNearRight) {
      if (spaceAbove > spaceBelow && !isNearTop) return "top-center";
      if (spaceBelow > spaceAbove && !isNearBottom) return "bottom-center";
    }

    // If both top and bottom are constrained, prioritize horizontal center placement
    if (isNearTop && isNearBottom) {
      if (spaceRight > spaceLeft && !isNearRight) return "right-center";
      if (spaceLeft > spaceRight && !isNearLeft) return "left-center";
    }

    // Fall back to our cascade logic for the best available position
    if (isNearRight) {
      if (!isNearTop) return "top-left";
      if (!isNearBottom) return "bottom-left";
      return "left-center";
    }

    if (isNearLeft) {
      if (!isNearTop) return "top-right";
      if (!isNearBottom) return "bottom-right";
      return "right-center";
    }

    if (isNearTop) {
      if (!isNearRight) return "bottom-right";
      if (!isNearLeft) return "bottom-left";
      return "bottom-center";
    }

    if (isNearBottom) {
      if (!isNearRight) return "top-right";
      if (!isNearLeft) return "top-left";
      return "top-center";
    }

    return initialPlacement;
  };

  const calculatePosition = (placement: PlacementType): Position => {
    if (!floatingRef.current || !containerRef.current) {
      return { top: 0, left: 0 };
    }

    const floatingRect = floatingRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();

    // We use a consistent gap of 8px between the trigger and floating element
    const gap = 8;

    switch (placement) {
      case "top-center":
        return {
          // Position above the trigger, centered horizontally
          top: containerRect.top - floatingRect.height - gap,
          left:
            containerRect.left + (containerRect.width - floatingRect.width) / 2,
        };

      case "bottom-center":
        return {
          // Position below the trigger, centered horizontally
          top: containerRect.bottom + gap,
          left:
            containerRect.left + (containerRect.width - floatingRect.width) / 2,
        };

      case "left-center":
        return {
          // Position to the left of the trigger, centered vertically
          top:
            containerRect.top +
            (containerRect.height - floatingRect.height) / 2,
          left: containerRect.left - floatingRect.width - gap,
        };

      case "right-center":
        return {
          // Position to the right of the trigger, centered vertically
          top:
            containerRect.top +
            (containerRect.height - floatingRect.height) / 2,
          left: containerRect.right + gap,
        };

      case "top-left":
        return {
          // Position above the trigger, aligned to its right edge
          top: containerRect.top - floatingRect.height - gap,
          left: containerRect.right - floatingRect.width,
        };

      case "top-right":
        return {
          // Position above the trigger, aligned to its left edge
          top: containerRect.top - floatingRect.height - gap,
          left: containerRect.left,
        };

      case "bottom-left":
        return {
          // Position below the trigger, aligned to its right edge
          top: containerRect.bottom + gap,
          left: containerRect.right - floatingRect.width,
        };

      case "bottom-right":
        return {
          // Position below the trigger, aligned to its left edge
          top: containerRect.bottom + gap,
          left: containerRect.left,
        };

      default:
        return {
          top: containerRect.bottom + gap,
          left: containerRect.left,
        };
    }
  };

  useEffect(() => {
    if (isVisible) {
      // Calculate initial position immediately
      const newPlacement = calculateOptimalPlacement();
      setActualPlacement(newPlacement);
      setPosition(calculatePosition(newPlacement));

      // Set up event listeners for updates
      const updatePosition = () => {
        const newPlacement = calculateOptimalPlacement();
        if (newPlacement !== actualPlacement) {
          setActualPlacement(newPlacement);
        }
        setPosition(calculatePosition(newPlacement));
      };

      window.addEventListener("scroll", updatePosition, true);
      window.addEventListener("resize", updatePosition);

      return () => {
        window.removeEventListener("scroll", updatePosition, true);
        window.removeEventListener("resize", updatePosition);
      };
    } else {
      // Reset position when hidden
      setPosition(null);
    }
  }, [isVisible, initialPlacement]);

  const visibleStyle: CSSProperties = position
    ? {
        position: "fixed",
        top: position.top,
        left: position.left,
        zIndex: 9999,
        opacity: 1,
        visibility: "visible",
      }
    : {
        position: "fixed",
        opacity: 0,
        visibility: "hidden",
        zIndex: -1,
      };

  return {
    actualPlacement,
    position,
    style: visibleStyle,
  };
};
