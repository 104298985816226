import React, { useEffect, useRef, useState } from "react";

import { useLocation } from "react-router-dom";
import { ArrowUpload20Filled } from "@fluentui/react-icons";
import { getHeaders, supabase } from "../../api";
import { Document } from "../../api";
import { DefaultButton, Modal, PrimaryButton } from "@fluentui/react";
import { useAppSelector } from "../../app/hooks";
import { fetchUserQueries } from "../../api/userConfig";
import { userApi } from "../../app/globals/userApiSlice";

const UserDocuments: React.FC = () => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const location = useLocation();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  function showUploadModal() {
    setIsModalVisible(true);
  }

  async function getUserDocs(): Promise<Document[]> {
    const { data, error } = await supabase.rpc("get_user_documents");
    if (error) {
      console.error("Error fetching user documents:", error);
      return [];
    }
    return data as Document[];
  }

  const beginDocumentUpload = async () => {
    if (
      !fileInputRef.current ||
      !fileInputRef.current.files ||
      fileInputRef.current.files.length === 0
    ) {
      alert("Please select a file to upload");
      return;
    }

    const file = fileInputRef.current.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("category", "example-category");

    try {
      const response = await fetch("/document/upload", {
        method: "POST",
        body: formData,
        headers: await getHeaders(),
      });

      if (response.ok) {
        alert("Document uploaded successfully");
        await getUserDocs();
        setIsModalVisible(false);
      } else {
        const errorText = await response.text();
        throw new Error(errorText);
      }
    } catch (error) {
      console.error("Error uploading document:", error);
      alert("An error occurred while uploading the document");
    }
  };
  const { data: session } = userApi.endpoints.getAuthState.useQueryState();

  useEffect(() => {
    if (!session?.user.id) {
      return;
    }
    getUserDocs().then(setDocuments);
    fetchUserQueries(session.user.id).then();
  }, [session]);

  return (
    <div className="p-4">
      {documents && (
        <div className="mt-3 rounded-lg bg-white p-4 shadow-md">
          <h2>Documents</h2>
          <div className="grid w-full grid-cols-1 gap-4 p-4 md:grid-cols-2 lg:grid-cols-3">
            {documents.map((document) => (
              <a
                href={`/admin/documents?document=${document.id}`}
                key={document.id}
                className="no-underline"
              >
                <div className="cursor-pointer rounded-lg border bg-white p-4 shadow-md transition-shadow duration-300 ease-in-out hover:shadow-lg">
                  <div className="flex flex-col">
                    <span className="mb-2 text-lg font-semibold text-blue-600">
                      {document.name}
                    </span>
                    <span className="text-sm text-gray-600">{document.id}</span>
                  </div>
                </div>
              </a>
            ))}

            <button
              className="cursor-pointer rounded-lg border bg-white p-4 text-lg shadow-md transition-shadow duration-300 ease-in-out hover:shadow-lg"
              onClick={showUploadModal}
            >
              <ArrowUpload20Filled />
              Upload Document
            </button>
          </div>
        </div>
      )}
      <Modal
        isOpen={isModalVisible}
        onDismiss={() => setIsModalVisible(false)}
        isBlocking={false}
        containerClassName="ms-modalExample-container"
      >
        <div className="ms-modalExample-header">
          <span>Upload Document</span>
        </div>
        <div className="ms-modalExample-body">
          <p>Select a file to upload:</p>
          <input ref={fileInputRef} type="file" />
        </div>
        <div className="ms-modalExample-footer">
          <PrimaryButton onClick={beginDocumentUpload} text="Upload" />
          <DefaultButton
            onClick={() => setIsModalVisible(false)}
            text="Cancel"
            style={{ marginLeft: "8px" }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default UserDocuments;
